<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <Breadcrumb nombreActual="Gestor Usuario" nombreAnterior="Usuario" urlAnterior="/listar_usuarios"></Breadcrumb>
        <section class="ficha ficha--crear" v-if="routeParams && usuario">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <small class="float-right">
                                    <a @click="editar=!editar;" class="btn btn-success btn-sm float-right" id="little_espaciado" title="Editar información personal">
                                        <font-awesome-icon v-if="!editar" :icon="['fas', 'edit']" />
                                        <font-awesome-icon v-if="editar" :icon="['fas', 'times']" />
                                    </a>
                                    <a v-if="usuario.is_active" @click="deactivateAlert(usuario)" role="button" class="btn btn--alert-disabled2 float-right" id="little_espaciado" title="Desactivar acceso a usuario"><font-awesome-icon :icon="['fas', 'ban']" /></a>
                                    <a v-if="!usuario.is_active" @click="activateUser(usuario)" role="button" class="btn btn--alert-nondisabled2 float-right" id="little_espaciado" title="Activar acceso a usuario"><font-awesome-icon :icon="['fas', 'circle']" /></a>
                                    <!--<a @click="deleteAlert(usuario)" role="button" class="btn btn-danger btn-sm float-right" id="little_espaciado"><font-awesome-icon :icon="['fas', 'trash-alt']" /></a>-->
                                </small>
                                <h3>Datos del usuario</h3>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label>Nombres*</label>
                                        <input type="text" name="" class="form-control" v-model="usuario.first_name" :disabled='!editar'>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Apellidos*</label>
                                        <input type="text" name="" class="form-control" v-model="usuario.last_name" :disabled='!editar'>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>R.U.N.*</label>
                                        <ValidationProvider rules="rut" v-slot="{ errors }">
                                            <input v-model="usuario.rut" type="text" name="rut" class='form-control' v-rut:live :disabled='!editar' @input="checkRutUser">
                                            <div class="invalid-error" id="custom_err" v-show="errors[0]">
                                                RUT inválido
                                            </div>
                                             <div class="invalid-error" id="custom_err" v-if='r_r'>
                                                R.U.N. en uso
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Correo Electrónico*</label>
                                        <input type="email" name="" class="form-control" v-model="usuario.email" :disabled='!editar' @input="checkEmailUser">
                                         <div class="invalid-error" id="custom_err" v-if='e_r'>
                                            Email en Uso
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Número Telefónico</label>
                                        <input type="text" name="" class="form-control" v-model="usuario.numero_telefonico" :disabled='!editar'>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Cargo</label>
                                        <input type="text" name="" class="form-control" v-model="usuario.cargo" :disabled='!editar'>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Tipo de Usuario* <a href="#" v-b-tooltip.hover title="Administrador (Administra todo)
        Revisor Ministerio (Puede editar fichas/monitoreos previamente creados y asignados por el administrador)
        Encargado Agencia (Puede ver y editar todas las fichas/monitoreos de su agencia)
        Encargado Subdirección (Puede ver y editar todas las fichas/monitoreos de su subdirección o gerencia)
        Revisor Instrumento (Puede ver y editar únicamente los instrumentos que tenga asignados)" class="col-md-1"><font-awesome-icon :icon="['fas', 'info-circle']" /></a></label>
                                        <select v-model="usuario.tipo_usuario" class="form-control" :disabled='!editar'>
                                            <option v-for="tipos in tipoUsuarios" :value="tipos.tipo" v-bind:key="tipos.tipo">
                                                {{tipos.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6" v-if="showSelector === true">
                                        <label>Agencia a la que pertenece*</label>
                                        <select v-model="usuario.agencia" class="form-control" :disabled='!editar'>
                                            <option v-for="agencia in agenciasSelector" :value="agencia.id" v-bind:key="agencia.id">
                                                {{agencia.nombre}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="btns btns--right" v-if="editar">
                                    <a v-if="!r_r && !e_r" @click="updateUsuario(usuario);" class="btn btn-primary">Guardar</a>
                                    <a class="btn btn-danger" v-if="r_r || e_r">Guardar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card" v-if="!editar">
                            <div class="card-body">
                                <h3>Permisos</h3>
                                <div class="row" v-if="usuario.agencia || usuario.tipo_usuario === 'Administrador' || usuario.tipo_usuario === 'Revisor Ministerio'">
                                    <div class="col-md-12">
                                        <b-card no-body>
                                            <b-tabs pills card vertical>
                                                <b-tab v-for="agencia in agencias" v-bind:key="agencia.id" :title="agencia.nombre">
                                                    <b-card-text>
                                                        <table class="col-md-6 table table-stripped table-hover table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <td>¿Tiene permisos sobre el Monitoreo de Procesos?</td>
                                                                    <td>Ver</td>
                                                                    <td>Editar</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <td></td>
                                                                    <td>
                                                                        <span v-if="!tienePermiso('ver', 'monitoreoprocesos', agencia.id)" @click="agregarPermiso('ver', 'monitoreoprocesos', agencia.id)">
                                                                            <font-awesome-icon :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                                                                        </span>
                                                                        <span v-if="tienePermiso('ver', 'monitoreoprocesos', agencia.id)" @click="quitarPermiso('ver', 'monitoreoprocesos', agencia.id);quitarPermiso('editar', 'monitoreoprocesos', agencia.id)">
                                                                            <font-awesome-icon :icon="['fas', 'toggle-on']" class="active"/>
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span v-if="!tienePermiso('editar', 'monitoreoprocesos', agencia.id)" @click="agregarPermiso('editar', 'monitoreoprocesos', agencia.id);agregarPermiso('ver', 'monitoreoprocesos', agencia.id)">
                                                                            <font-awesome-icon :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                                                                        </span>
                                                                        <span v-if="tienePermiso('editar', 'monitoreoprocesos', agencia.id)" @click="quitarPermiso('editar', 'monitoreoprocesos', agencia.id)">
                                                                            <font-awesome-icon :icon="['fas', 'toggle-on']" class="active"/>
                                                                        </span>
                                                                    </td>
                                                            </tbody>
                                                        </table>
                                                        <table class="table table-stripped table-hover table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>Ver</td>
                                                                    <td>Editar</td>
                                                                    <!-- <td v-show="usuario.tipo_usuario ==='Administrador'">Crear</td> -->
                                                                </tr>
                                                            </thead>
                                                            <tbody v-for="subdireccion in agencia.subdirecciones" v-bind:key="subdireccion.id">
                                                                <th> {{subdireccion.nombre}}</th>
                                                                <td v-if="usuario.tipo_usuario != 'Revisor Instrumento'">
                                                                    <span v-if="!tienePermiso('ver', 'subdireccion', subdireccion.id)" @click="agregarPermiso('ver', 'subdireccion', subdireccion.id)">
                                                                        <font-awesome-icon :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                                                                    </span>
                                                                    <span v-if="tienePermiso('ver', 'subdireccion', subdireccion.id)" @click="quitarPermiso('ver', 'subdireccion', subdireccion.id);quitarPermiso('editar', 'subdireccion', subdireccion.id)">
                                                                        <font-awesome-icon :icon="['fas', 'toggle-on']" class="active"/>
                                                                    </span>
                                                                </td>
                                                                <td v-if="usuario.tipo_usuario != 'Revisor Instrumento'">
                                                                    <span v-if="!tienePermiso('editar', 'subdireccion', subdireccion.id)" @click="agregarPermiso('editar', 'subdireccion', subdireccion.id)">
                                                                        <font-awesome-icon :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                                                                    </span>
                                                                    <span v-if="tienePermiso('editar', 'subdireccion', subdireccion.id)" @click="quitarPermiso('editar', 'subdireccion', subdireccion.id)">
                                                                        <font-awesome-icon :icon="['fas', 'toggle-on']" class="active"/>
                                                                    </span>
                                                                </td>
<!--                                                                 <td v-show="usuario.tipo_usuario ==='Administrador'">
                                                                    <span v-if="!tienePermiso('crear', 'subdireccion', subdireccion.id)" @click="agregarPermiso('crear', 'subdireccion', subdireccion.id)">
                                                                        <font-awesome-icon :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                                                                    </span>
                                                                    <span v-if="tienePermiso('crear', 'subdireccion', subdireccion.id)" @click="quitarPermiso('crear', 'subdireccion', subdireccion.id)">
                                                                        <font-awesome-icon :icon="['fas', 'toggle-on']" class="active"/>
                                                                    </span>
                                                                </td> -->
                                                                <tr v-for="instrumento in subdireccion.instrumentos" v-bind:key="instrumento.id">
                                                                    <td>{{instrumento.nombre}}</td>
                                                                <td>
                                                                    <span v-if="!tienePermiso('ver', 'instrumento', instrumento.id)" @click="agregarPermiso('ver', 'instrumento', instrumento.id)">
                                                                        <font-awesome-icon :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                                                                    </span>
                                                                    <span v-if="tienePermiso('ver', 'instrumento', instrumento.id)" @click="quitarPermiso('ver', 'instrumento', instrumento.id);quitarPermiso('editar', 'instrumento', instrumento.id)">
                                                                        <font-awesome-icon :icon="['fas', 'toggle-on']" class="active"/>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span v-if="!tienePermiso('editar', 'instrumento', instrumento.id)" @click="agregarPermiso('editar', 'instrumento', instrumento.id); agregarPermiso('ver', 'instrumento', instrumento.id)">
                                                                        <font-awesome-icon :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                                                                    </span>
                                                                    <span v-if="tienePermiso('editar', 'instrumento', instrumento.id)" @click="quitarPermiso('editar', 'instrumento', instrumento.id)">
                                                                        <font-awesome-icon :icon="['fas', 'toggle-on']" class="active"/>
                                                                    </span>
                                                                </td>
                                                                <!--<td v-show="usuario.tipo_usuario ==='Administrador'">
                                                                    <span v-if="!tienePermiso('crear', 'instrumento', instrumento.id)" @click="agregarPermiso('crear', 'instrumento', instrumento.id)">
                                                                        <font-awesome-icon :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                                                                    </span>
                                                                    <span v-if="tienePermiso('crear', 'instrumento', instrumento.id)" @click="quitarPermiso('crear', 'instrumento', instrumento.id)">
                                                                        <font-awesome-icon :icon="['fas', 'toggle-on']" class="active"/>
                                                                    </span>
                                                                </td> -->
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </b-card-text>
                                                </b-tab>
                                            </b-tabs>
                                        </b-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'
import { ValidationProvider, extend } from 'vee-validate'
import { rutValidator } from 'vue-dni'
import Breadcrumb from '@/components/Breadcrumb.vue'

const Usuario = resource('/usuarios/api/crear_usuario', axios)
const Agencia = resource('/usuarios/api/listar_agencias', axios)
const Usuarios = resource('/usuarios/api/all_users', axios)

export default {
    name: 'CrearUsuario',
    components: {
        ValidationProvider,
        Breadcrumb
    },
    data () {
        return {
            usuario: null,
            usuarios: [],
            routeParams: this.$route.params.id,
            formUsuario: {},
            editar: false,
            tipoUsuario: {},
            agencias: [],
            agenciasSelector: [],
            showSelector: false,
            tipoUsuarios: [
                { tipo: 'Administrador', name: 'Administrador(a)' },
                { tipo: 'Revisor Ministerio', name: 'Revisor(a) Ministerio' },
                { tipo: 'Encargado Agencia', name: 'Encargado(a) Agencia' },
                { tipo: 'Encargado Subdirección', name: 'Encargado(a) Subdirección' },
                { tipo: 'Revisor Instrumento', name: 'Revisor(a) Instrumento' }
            ],
            show: true,
            e_r: false,
            r_r: false,
            email_original: null,
            rut_original: null
        }
    },
    methods: {
        getData () {
            if (this.routeParams) {
                Usuario.query({ id: this.routeParams }).then((res) => {
                    this.usuario = res.data.items[0]
                    // console.log(this.usuario)
                    this.show = false
                    this.email_original = res.data.items[0].email
                    this.rut_original = res.data.items[0].rut
                    /* console.log(res.data.items[0]) */
                })
                    .catch((error) => {
                        console.log(error)
                        this.show = false
                    })
            }
        },
        getAllUsers () {
            Usuarios.query({}).then((res) => {
                this.usuarios = res.data // .items
                // console.log(this.usuarios)
            })
                .catch((error) => {
                    console.log(error)
                })
        },
        updateUsuario (usuario) {
            if (this.usuario.tipo_usuario === 'Administrador') {
                this.usuario.is_staff = true
            } else {
                this.usuario.is_staff = false
            }
            var username = this.usuario.rut.replace(/\./g, '').toLowerCase()
            this.usuario.username = username
            this.show = true
            axios({
                method: 'put',
                url: '/usuarios/api/crear_usuario/' + usuario.id + '/',
                data: usuario
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.editar = false
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Usuario actualizado exitosamente'
                    })
                })
                .catch((response) => {
                    console.log(response)
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar el Usuario'
                    })
                    // console.log(response)
                })
        },
        deleteUsuario (usuario) {
            this.show = true
            axios({
                method: 'delete',
                url: '/usuarios/api/crear_usuario/' + usuario.id + '/'
            })
                .then((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Usuario eliminado exitosamente'
                    })
                    this.$router.push({ name: 'ListarUsuarios' })
                    location.reload()
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el Usuario'
                    })
                    console.log(response)
                })
        },
        getAgenciaSelector () {
            Agencia.query({ activo: true }).then((res) => {
                this.agenciasSelector = res.data
            })
        },
        getAgenciaPermisos () {
            Agencia.query({ activo: true, id: this.usuario.agencia }).then((res) => {
                this.agencias = res.data
            })
        },
        tienePermiso (tipo, elemento, id) {
            var temp = false
            if (this.usuario.permisos) {
                if (this.usuario.permisos.length > 0) {
                    this.usuario.permisos.filter((item) => {
                        if (item.tipo === tipo && item.sobre === elemento && item.id.includes(id)) {
                            temp = true
                        }
                    })
                }
            }
            return temp
        },
        agregarPermiso (tipo, elemento, id) {
            this.usuario.permisos.filter((item) => {
                if (item.tipo === tipo && item.sobre === elemento) {
                    if (!item.id.includes(id)) item.id.push(id)
                }
            })
            this.updateUsuario(this.usuario)
        },
        quitarPermiso (tipo, elemento, id) {
            this.usuario.permisos.filter((item) => {
                if (item.tipo === tipo && item.sobre === elemento) {
                    var index = item.id.indexOf(id)
                    if (item.id.includes(id)) item.id.splice(index, 1)
                }
            })
            this.updateUsuario(this.usuario)
        },
        deleteAlert (usuario) {
            this.$swal({
                title: 'Vas a eliminar el usuario',
                text: '¿Estás seguro? Se perderá la información asociada',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteUsuario(usuario)
                }
            })
        },
        deactivateAlert (usuario) {
            this.$swal({
                title: 'Vas a desactivar el usuario',
                text: '¿Estás seguro? El usuario no podrá acceder al sistema',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                usuario.is_active = false
                if (result.isConfirmed) {
                    this.updateUsuario(usuario)
                }
            })
        },
        activateUser (usuario) {
            usuario.is_active = true
            this.updateUsuario(usuario)
        },
        checkEmailUser () {
            if (this.usuario.email) {
                this.e_r = false
                this.usuarios.forEach(element => {
                    if (this.usuario.email !== this.email_original && element.email === this.usuario.email) {
                        // console.log('EXISTE')
                        this.e_r = true
                    }
                })
            } else {
                this.e_r = false
            }
        },
        checkRutUser () {
            if (this.usuario.rut) {
                this.r_r = false
                this.usuarios.forEach(element => {
                    if (this.usuario.rut !== this.rut_original && element.rut === this.usuario.rut) {
                        // console.log('EXISTE')
                        this.r_r = true
                    }
                })
            } else {
                this.r_r = false
            }
        }
    },
    created () {
        extend('rut', rutValidator)
    },
    mounted () {
        this.getData()
        this.getAllUsers()
        this.$watch('usuario.tipo_usuario', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                if (newLocale === 'Encargado Agencia' || newLocale === 'Encargado Subdirección' || newLocale === 'Revisor Instrumento') {
                    this.getAgenciaSelector()
                    this.showSelector = true
                } else if (newLocale === 'Administrador' || newLocale === ('Revisor Ministerio')) {
                    this.usuario.agencia = null
                    this.showSelector = false
                }
            }
        }, { immediate: true })
        this.$watch('usuario.agencia', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                this.getAgenciaPermisos()
            }
        }, { immediate: true })
    }
}
</script>
