<template>
    <div>
        <!-- <Breadcrumb></Breadcrumb> -->
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <section class="ficha" v-if="logged">
            <div class="container">
                <div class="home_name1">Bienvenido/a,</div>
                <div class="home_name2">{{usuario.get_nombre_completo}}</div>
                <br>
                <span>{{usuario.agencia__nombre}}</span>
                <div class="row align-items-center">
                    <div class="col-4">
                        <div class="card card--button">
                            <router-link to="/fichas_diseno" class="card--button__btn" id="home_dis">
                                <font-awesome-icon :icon="['fas', 'pencil-ruler']" />
                                <span class="card--button__text">Diseño</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card card--button">
                            <router-link to="/monitoreo_instrumentos" class="card--button__btn" id="home_mon">
                                <font-awesome-icon :icon="['fas', 'search']" />
                                <span class="card--button__text">Monitoreo</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card card--button">
                            <router-link to="/fichas_evaluacion" class="card--button__btn" id="home_eval">
                                <font-awesome-icon :icon="['fas', 'chart-line']" />
                                <span class="card--button__text">Evaluación</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card card--button">
                            <router-link to="/material_interes" class="card--button__btn">
                                <font-awesome-icon :icon="['fas', 'file-alt']" />
                                <span class="card--button__text">Material de Interés</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card card--button">
                            <modal-password-2 :usuario="usuario"></modal-password-2>
                        </div>
                    </div>
                    <div class="col-4" v-if="usuario.tipo_usuario==='Administrador' || usuario.is_superuser">
                        <div class="card card--button">
                            <router-link to="/panel_administracion" class="card--button__btn">
                                <font-awesome-icon :icon="['fas', 'cog']" />
                                <span class="card--button__text">Panel Administración</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--<Footer/>-->
        <!--<footer>
            <div class="row col-md-12" style="text-align: center;position:fixed;left:0;bottom:20px;">
                <p style="margin:auto;">Para consultas, contacte a <a style="text-decoration:none;" href="mailto:estudiosestadisticas@minciencia.gob.cl">estudiosestadisticas@minciencia.gob.cl</a></p>
            </div>
        </footer>-->
    </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'
import ModalPassword2 from '@/components/ModalPassword2.vue'
// import Footer from '@/components/Footer.vue'
// import Breadcrumb from '@/components/Breadcrumb.vue'

const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)

export default {
    name: 'Home',
    components: {
        ModalPassword2
        // Breadcrumb
    },
    async beforeCreate () {

    },
    data () {
        return {
            usuario: {},
            show: true,
            logged: false
        }
    },
    methods: {
    },
    mounted () {
        UsuarioActual.query({}).then((res) => {
            this.usuario = res.data[0]
            this.show = false
            this.logged = true
        })
            .catch((error) => {
                console.log(error)
                this.show = false
                this.logged = false
                this.$router.push('/login')
            })
    }
}
</script>
