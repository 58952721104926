<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <Breadcrumb v-if="instrumento__nombre" :nombreActual="'Ficha Indicador: ' + instrumento__nombre" nombreAnterior="Fichas Diseño" urlAnterior="/fichas_diseno"></Breadcrumb>
        <section class="ficha">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <h2>Indicadores: {{anio}}<span class="badge" v-bind:class="{ 'bg-en-proceso': estadoFicha === 'En Proceso', 'bg-por-validar': estadoFicha ==='Por Validar', 'bg-validada': estadoFicha === 'Validada', 'bg-bloqueada': estadoFicha === 'Bloqueada'}">{{estadoFicha}}</span></h2>
                        <div class="row">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><router-link :to="'/ficha/' + fichaId + '/' + instrumentoId + '/' + anio">Ficha de Diseño</router-link></li>
                                    <li class="breadcrumb-item active" aria-current="page">Indicadores</li>
                                    <li class="breadcrumb-item"><router-link :to="'/fichateoriacambio/' + fichaId + '/' + instrumentoId + '/' + anio">Teoría de Cambio</router-link></li>
                                </ol>
                            </nav>
                        </div>
                        <span>Complete únicamente los campos que apliquen</span>
                        <b-card no-body class="p-3">
                            <b-tabs tabs v-if="tipoIndicadores">
                                <b-tab v-for="tipoindicador in tipoIndicadores" v-bind:key="tipoindicador.id" v-model="tipoIndicador" :title="tipoindicador.nombre" @click="tipoIndicador=tipoindicador.id;getData()">
                                    <b-card-body>
                                        <ModalIndicador v-if="!['Bloqueada', 'Validada'].includes(estadoFicha)" :modal="'modal-create-indicador-tipo-' + tipoindicador.id" :ficha="fichaId" :instrumento="instrumentoId" :tipoIndicador="tipoindicador.id" :tipoIndicadorNombre="tipoindicador.nombre" :editar="false" :formIndicador="{}" :anio_="anio" @accept="getData"></ModalIndicador>
                                        <ModalComentarios :ficha="fichaId" :instrumento="instrumentoId" :tipoIndicador="tipoindicador.id" :titulo="tipoindicador.nombre" :modal="'tipoindicador-' + tipoindicador.id"></ModalComentarios>
                                        <div class="row col-md-12">
                                            <table class="table table-stripped table-hover table-bordered" id="tabla-indicadores">
                                                <thead>
                                                    <tr>
                                                        <td>Nombre</td>
                                                        <td>Detalle Metodología</td>
                                                        <td>Periodicidad</td>
                                                        <td>Fuente de Información</td>
                                                        <!--<td>Situación actual</td>
                                                        <td>Situación esperada</td>
                                                        <td>Justificación de que ese sea la meta</td>-->
                                                        <td>Fórmula</td>
                                                        <td>Unidad de Medida</td>
                                                        <td>Definición de Variables</td>
                                                        <td>¿Es Dipres?</td>
                                                        <td>Acción</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="indicador in indicadores" v-bind:key="indicador.id">
                                                        <td>{{indicador.nombre}}</td>
                                                        <td>{{indicador.formula_calculo}}</td>
                                                        <td>{{indicador.get_periodicidad}}</td>
                                                        <td>{{indicador.fuente_informacion}}</td>
                                                        <!--<td>{{indicador.situacion_actual}}</td>
                                                        <td>{{indicador.situacion_esperada}}</td>
                                                        <td>{{indicador.justificacion_meta}}</td>-->
                                                        <td>{{ formula(indicador.unidad_medida) }}</td>
                                                        <td>{{indicador.unidad_medida__nombre}} {{(indicador.unidad_medida__unidad)}} - {{indicador.unidad_medida__formula}}</td>
                                                        <td>
                                                            <span v-for="variable in indicador.variables" v-bind:key="variable.variable">
                                                                <b>{{variable.variable}}</b>: {{variable.valor}}<br>
                                                            </span>
                                                        </td>
                                                        <td>{{indicador.dipres ? "Sí" : "No"}}</td>
                                                        <td>
                                                            <ModalIndicador v-if="!['Bloqueada', 'Validada'].includes(estadoFicha)" :modal="'modal-update-indicador-id-' + indicador.id + '-' + tipoindicador.id" :ficha="fichaId" :instrumento="instrumentoId" :tipoIndicador="tipoindicador.id" :tipoIndicadorNombre="tipoindicador.nombre" :editar="true" :formIndicador="indicador" :anio_="anio" @accept="getData"></ModalIndicador>
                                                            <ModalComentarios :ficha="fichaId" :instrumento="instrumentoId" :indicador="indicador.id" :titulo="indicador.nombre" :modal="'indicador-' + indicador.id"></ModalComentarios>
                                                            <a class="btn btn-danger btn-sm" @click="deleteAlert(indicador)" v-if="!['Bloqueada', 'Validada'].includes(estadoFicha) && indicador.monitoreo_instrumento.length === 0"><font-awesome-icon :icon="['fas', 'trash-alt']" /> Eliminar</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </b-card-body>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'
import ModalComentarios from '@/components/ModalComentarios.vue'
import ModalIndicador from '@/components/ModalIndicador.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'

// const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)
const TipoIndicador = resource('/indicadores/api/listar_tipo_indicador', axios)
const Indicador = resource('/indicadores/api/crear_indicador_ficha/', axios)
const UnidadMedida = resource('/indicadores/api/listar_unidad_medida', axios)

export default {
    name: 'FichaIndicadores',
    components: {
        ModalComentarios,
        ModalIndicador,
        Breadcrumb
    },
    data () {
        return {
            fichaId: this.$route.params.fichaId,
            instrumentoId: this.$route.params.instrumentoId,
            anio: this.$route.params.anio,
            tipoIndicadores: [],
            indicadores: [],
            tipoIndicador: null,
            estadoFicha: {},
            instrumento__nombre: '',
            unidadMedidas: [],
            show: true // ,
            // isAdmin: false,
            // usuario: null
        }
    },
    methods: {
        getData () {
            // console.log('test1')
            Indicador.query({ instrumento: this.instrumentoId, ficha: this.fichaId, tipo_indicador: this.tipoIndicador, anio: this.anio }).then((res) => {
                this.indicadores = res.data
                this.show = false
                // console.log('data get')
                // console.log(res.data)
            }, (error) => {
                this.show = false
                console.log(error)
            })
            axios({
                method: 'put',
                url: '/ficha_instrumentos/api/ficha_instrumento/' + this.fichaId + '/estado/',
                data: { instrumento: this.instrumentoId }
            })
                .then((response) => {
                    this.estadoFicha = response.data
                    // console.log('bon jour')
                })
                .catch((response) => {
                    console.log(response)
                })
            axios({
                method: 'put',
                url: '/ficha_instrumentos/api/ficha_instrumento/' + this.instrumentoId + '/instrumento/'
            })
                .then((response) => {
                    this.instrumento__nombre = response.data
                })
                .catch((response) => {
                    console.log(response)
                })
        },
        deleteAlert (indicador) {
            this.$swal({
                title: 'Vas a eliminar el Indicador',
                text: '¿Estás seguro? Se perderán todos los datos',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteIndicador(indicador)
                }
            })
        },
        deleteIndicador (indicador) {
            this.show = true
            axios({
                method: 'delete',
                url: '/indicadores/api/crear_indicador_ficha/' + indicador.id + '/'
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Indicador eliminado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el Indicador'
                    })
                })
        },
        formula (umedida) {
            return this.unidadMedidas.filter(unidad => {
                if (unidad.id === umedida) {
                    // console.log(umedida)
                    // console.log(unidad.formula)
                    return unidad.formula // this.formulaMedida = unidad.formula
                }
                // console.log('hola')
            })[0].formula
        }
    },
    mounted () {
        TipoIndicador.query({}).then((res) => {
            this.tipoIndicadores = res.data
            this.tipoIndicador = res.data[0].id
            if (this.tipoIndicador !== null) {
                this.getData()
            }
        }, function (error) {
            console.log(error)
        })

        UnidadMedida.query({}).then((res) => {
            this.unidadMedidas = res.data
        }, (error) => {
            console.log(error)
        })
        // this.getData()
        /* UsuarioActual.query({}).then((res) => {
            this.usuario = res.data[0]
            // console.log(this.usuario)
            if (this.usuario.tipo_usuario === 'Administrador' || this.usuario.tipo_usuario === 'Revisor Ministerio' || this.usuario.is_superuser) {
                this.isAdmin = true
            }
        }) */
    }
}
</script>
