<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <Breadcrumb nombreActual="Usuarios" nombreAnterior="Panel de Administración" urlAnterior="/panel_administracion"></Breadcrumb>
        <b-modal id="modal-create-user" size="lg" title="Crear Nuevo Usuario(a)">
            <div class="row">
                <div class="form-group col-md-6">
                    <label>Nombres*</label>
                    <input type="text" name="" class="form-control" v-model="formUsuario.first_name" @input=checkob1>
                </div>
                <div class="form-group col-md-6">
                    <label>Apellidos*</label>
                    <input type="text" name="" class="form-control" v-model="formUsuario.last_name" @input=checkob2>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label>R.U.N.*</label>
                    <ValidationProvider rules="rut" v-slot="{ errors }">
                        <input v-model="formUsuario.rut" type="text" name="rut" class='form-control' v-rut:live @input="checkRutUser">
                        <div class="invalid-error" id="custom_err" v-show="errors[0]">
                            R.U.N. inválido
                        </div>
                        <div class="invalid-error" id="custom_err" v-if='r_r'>
                            R.U.N. en uso
                        </div>
                    </ValidationProvider>
                </div>
                <div class="form-group col-md-6">
                    <label>Correo Electrónico*</label>
                    <input type="email" name="" class="form-control" v-model="formUsuario.email" @input="checkEmailUser">
                    <div class="invalid-error" id="custom_err" v-if='e_r'>
                       Email en Uso
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label>Número Telefónico</label>
                    <input type="text" name="" class="form-control" v-model="formUsuario.numero_telefonico">
                </div>
                <div class="form-group col-md-6">
                    <label>Cargo</label>
                    <input type="text" name="" class="form-control" v-model="formUsuario.cargo">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label>Tipo de Usuario(a)*<a href="#" v-b-tooltip.hover title="Administrador (Administra todo)
        Revisor Ministerio (Puede editar fichas/monitoreos previamente creados y asignados por el administrador)
        Encargado Agencia (Puede ver y editar todas las fichas/monitoreos de su agencia)
        Encargado Subdirección (Puede ver y editar todas las fichas/monitoreos de su subdirección o gerencia)
        Revisor Instrumento (Puede ver y editar únicamente los instrumentos que tenga asignados)" class="col-md-1"><font-awesome-icon :icon="['fas', 'info-circle']" /></a></label>
                    <select v-model="formUsuario.tipo_usuario" class="form-control">
                        <option v-for="tipos in tipoUsuarios" :value="tipos.tipo" v-bind:key="tipos.tipo">
                            {{tipos.name}}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-6" v-if="showSelector === true">
                    <label>Agencia a la que pertenece*</label>
                    <select v-model="formUsuario.agencia" class="form-control" required>
                        <option v-for="agencia in agenciasSelector" :value="agencia.id" v-bind:key="agencia.id">
                            {{agencia.nombre}}
                        </option>
                    </select>
                </div>
            </div>
            <template #modal-footer="{ cancel }">
                <b-button size="md" variant="outline-secondary" @click="cancel()">
                    Cancelar
                </b-button>
                <a @click="createUsuario();" class="btn btn-primary" v-if="!r_r && !e_r && val1 && val2 && val3 && val4">Guardar y Continuar <font-awesome-icon :icon="['fas', 'arrow-right']" /></a>
                <a class="btn btn-danger" v-if="r_r || e_r || !val1 || !val2 || !val3 || !val4">Guardar y Continuar <font-awesome-icon :icon="['fas', 'arrow-right']" /></a>
            </template>
        </b-modal>
        <section class="ficha">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card--section">
                            <div class="card-header">
                                <h2 class="card-title d-inline-block">Usuarios</h2>
                                <b-button v-b-modal.modal-create-user variant="primary" class="btn btn-primary float-right">
                                    <font-awesome-icon :icon="['fas', 'plus']" />
                                        Crear usuario(a)
                                </b-button>
                            </div>
                            <div class="card-body">
                            <div class="alert alert-info" role="alert" v-if="usuarios.length === 0">
                                        No hay información disponible
                                    </div>
                                <div class="table-responsive">
                                    <table class="table table-stripped table-hover table-bordered" id="tabla-usuarios" v-if="usuarios.length > 0">
                                        <thead>
                                            <tr>
                                                <td>Nombre</td>
                                                <td>R.U.N.</td>
                                                <td>Correo Electrónico</td>
                                                <td>Número Telefónico</td>
                                                <td>Cargo</td>
                                                <td>Tipo de Usuario</td>
                                                <td>¿Activo?</td>
                                                <td>Acción</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="usuario in UsersQuery" v-bind:key="usuario.id">
                                                <td>{{usuario.get_nombre_completo}}</td>
                                                <td>{{usuario.rut}} </td>
                                                <td>{{usuario.email}} </td>
                                                <td>{{usuario.numero_telefonico}}</td>
                                                <td>{{usuario.cargo}}</td>
                                                <td>{{cbts(usuario.tipo_usuario)}}</td>
                                                <td v-if="usuario.is_active">Si</td>
                                                <td v-else>No</td>
                                                <td>
                                                    <div class="btns mt-0">
                                                        <router-link :to="'/editar_usuario/' + usuario.id" class="btn btn-secondary btn-sm"><font-awesome-icon :icon="['fas', 'edit']" /> Editar</router-link>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                  <b-pagination
                                  v-if="usuarios.length > 0"
                                  v-model="currentPage"
                                  :total-rows="rows"
                                  :per-page="perPage"
                                  aria-controls="tabla-usuarios"
                                  ></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import router from '../router/index.js'
import { ValidationProvider, extend } from 'vee-validate'
import { rutValidator } from 'vue-dni'
import Breadcrumb from '@/components/Breadcrumb.vue'

const Usuario = resource('/usuarios/api/all_users', axios)
const Agencia = resource('/usuarios/api/listar_agencias', axios)

export default {
    name: 'ListarUsuarios',
    components: {
        ValidationProvider,
        Breadcrumb
    },
    data () {
        return {
            usuarios: [],
            currentPage: 1,
            perPage: 10, // null,
            rows: null,
            page: null,
            formUsuario: {},
            agenciasSelector: [],
            tipoUsuarios: [
                { tipo: 'Administrador', name: 'Administrador(a)' },
                { tipo: 'Revisor Ministerio', name: 'Revisor(a) Ministerio' },
                { tipo: 'Encargado Agencia', name: 'Encargado(a) Agencia' },
                { tipo: 'Encargado Subdirección', name: 'Encargado(a) Subdirección' },
                { tipo: 'Revisor Instrumento', name: 'Revisor(a) Instrumento' }
            ],
            showSelector: false,
            show: true,
            e_r: false,
            r_r: false,
            val1: false,
            val2: false,
            val3: false,
            val4: false
        }
    },
    methods: {
        getData () {
            // Usuario.query({ page: this.currentPage, per_page: 10 }).then((res) => {
            Usuario.query({}).then((res) => {
                this.usuarios = res.data // .items
                // this.perPage = res.data.paginate.per_page
                this.rows = res.data.length // res.data.paginate.count
                this.show = false
            })
                .catch((error) => {
                    console.log(error)
                    this.show = false
                })
        },
        getAgenciaSelector () {
            Agencia.query({ activo: true }).then((res) => {
                this.agenciasSelector = res.data
            })
        },
        createUsuario () {
            this.show = true
            this.formUsuario.permisos = []
            if (this.formUsuario.tipo_usuario === 'Administrador') {
                this.formUsuario.is_staff = true
            }
            var username = this.formUsuario.rut.replace(/\./g, '').toLowerCase()
            this.formUsuario.username = username
            axios({
                method: 'post',
                url: '/usuarios/api/crear_usuario/',
                data: this.formUsuario
            })
                .then((response) => {
                    this.show = false
                    router.push({ name: 'EditarUsuario', params: { id: response.data.id } })
                    location.reload()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Usuario creado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear el Usuario'
                    })
                })
        },
        checkEmailUser () {
            this.val3 = false
            if (this.formUsuario.email) {
                this.val3 = true
                this.e_r = false
                this.usuarios.forEach(element => {
                    if (element.email === this.formUsuario.email) {
                        // console.log('EXISTE')
                        this.e_r = true
                    }
                })
            } else {
                this.e_r = false
            }
        },
        checkRutUser () {
            this.val4 = false
            if (this.formUsuario.rut) {
                this.val4 = true
                this.r_r = false
                this.usuarios.forEach(element => {
                    if (element.rut === this.formUsuario.rut) {
                        // console.log('EXISTE')
                        this.r_r = true
                    }
                })
            } else {
                this.r_r = false
            }
        },
        checkob1 () {
            this.val1 = false
            if (this.formUsuario.first_name) {
                this.val1 = true
            }
        },
        checkob2 () {
            this.val2 = false
            if (this.formUsuario.last_name) {
                this.val2 = true
            }
        },
        cbts (value) {
            if (value) {
                return this.tipoUsuarios.filter(d => d.tipo === value)[0].name
            }
        }
    },
    created () {
        extend('rut', rutValidator)
    },
    mounted () {
        this.getData()
        /*
        this.$watch('currentPage', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                this.getData()
            }
        }, { immediate: true })
        */
        this.$watch('formUsuario.tipo_usuario', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                if (newLocale === 'Encargado Agencia' || newLocale === 'Encargado Subdirección' || newLocale === 'Revisor Instrumento') {
                    this.getAgenciaSelector()
                    this.showSelector = true
                } else if (newLocale === 'Administrador' || newLocale === ('Revisor Ministerio')) {
                    this.formUsuario.agencia = null
                    this.showSelector = false
                }
            }
        }, { immediate: true })
    },
    computed: {
        UsersQuery () {
            const startIndex = this.perPage * (this.currentPage - 1)
            const endIndex = startIndex + this.perPage
            return this.usuarios.slice(startIndex, endIndex)
        }
    }
}
</script>
