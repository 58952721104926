<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <div class="container-fluid">
            <div class="row">
            <div class="col-md-7">
                <Breadcrumb v-if="fichainstrumento && fichainstrumento.ficha__tipo_ficha==='Diseño'" :nombreActual="'Ficha de Diseño: ' + fichainstrumento.instrumento__nombre" nombreAnterior="Fichas de Diseño" urlAnterior="/fichas_diseno"></Breadcrumb>
                <Breadcrumb v-if="fichainstrumento && fichainstrumento.ficha__tipo_ficha==='Evaluación'" :nombreActual="'Ficha de Evaluación: ' + fichainstrumento.instrumento__nombre" nombreAnterior="Fichas de Evaluación" urlAnterior="/fichas_evaluacion"></Breadcrumb>
            </div>
            <div class="col-md-5">
            <div class="float-right mt-4">
                <span v-if="fichainstrumento && isAdmin">
                    <a v-if="fichainstrumento.activo" class="btn btn-danger" @click="inhabilitarFichaInstrumento(fichainstrumento);"><font-awesome-icon :icon="['fas', 'ban']" /> Inhabilitar</a>
                    <a v-if="!fichainstrumento.activo" class="btn btn-secondary mr-2" @click="inhabilitarFichaInstrumento(fichainstrumento);"><font-awesome-icon :icon="['fas', 'circle']" /> Habilitar</a>
                </span>
                <a class="btn btn-primary ml-2" :href="urlDescarga" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar PDF</a>
            </div>
            </div>
        </div>
        </div>
        <section class="ficha">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-8">
                        <!-- <div class="card">
                            <div class="card-body"> -->
                        <div class="section__container">
                            <div class="card card--section" v-if="fichainstrumento">
                                <div class="card-header">
                                    <h2 class="card-title">{{fichainstrumento.ficha__nombre | uppercase}} - {{fichainstrumento.anio}} <span class="badge" v-bind:class="{ 'bg-en-proceso': fichainstrumento.estado === 'En Proceso', 'bg-por-validar': fichainstrumento.estado ==='Por Validar', 'bg-validada': fichainstrumento.estado === 'Validada', 'bg-bloqueada': fichainstrumento.estado === 'Bloqueada'}">{{fichainstrumento.estado}}</span></h2>
                                    <h3 class="card-subtitle">{{fichainstrumento.instrumento__nombre | uppercase}}</h3>
                                </div>
                                <div>
                                    <!-- <ModalComentarios :ficha="fichaId" :instrumento="instrumentoId" titulo="Ficha" :modal="'ficha-' + fichaId"></ModalComentarios> -->
                                </div>
                                <div class="card-body">
                                    <div class="row" v-for="seccion in fichainstrumento.secciones" v-bind:key="seccion.id">
                                        <div class="col-md-12">
                                            <h3 >{{seccion.format_nombre}}
                                                <div class="d-inline-block">
                                                    <ModalComentarios :ficha="fichaId" :instrumento="instrumentoId" :seccion="seccion.id" :titulo="seccion.format_nombre" :modal="'seccion-' + seccion.id"></ModalComentarios>
                                                </div>
                                            </h3>
                                            <div class="row" v-for="subseccion in seccion.subsecciones" v-bind:key="subseccion.id">
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <h4 class="section__title">{{subseccion.format_nombre}}
                                                                <div class="d-inline-block">
                                                                    <ModalComentarios :ficha="fichaId" :instrumento="instrumentoId" :subseccion="subseccion.id" :titulo="subseccion.format_nombre" :modal="'subseccion-' + subseccion.id"></ModalComentarios>
                                                                </div>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <p v-html="subseccion.descripcion" v-b-tooltip.hover title="Descripción"></p>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <i><p v-html="subseccion.notas" style="font-size:12px;" v-b-tooltip.hover title="Notas adicionales"></p></i>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="fichainstrumento.respuestas.length > 0">
                                                        <div class="col-md-12" v-for="elemento in fichainstrumento.respuestas" v-bind:key="elemento.id">
                                                            <div v-if="subseccion.id == elemento.subseccion">
                                                                <span v-if="elemento.elemento && elemento.elemento.type !=='table'">
                                                                    <FormulateInput :type="elemento.elemento.type" :validation="elemento.elemento.validation" :options="elemento.elemento.options" v-model="elemento.elemento.content" :disabled="fichainstrumento.estado==='Validada' || fichainstrumento.estado === 'Bloqueada'"/>
                                                                    <!--  v-on:blur="updateElemento(elemento)" -->
                                                                </span>
                                                                <span v-if="elemento.elemento && elemento.elemento.type ==='table'">
                                                                    <ckeditor
                                                                        :editor="editor"
                                                                        v-model="elemento.elemento.content"
                                                                        :config="editorConfig"
                                                                        :disabled="fichainstrumento.estado==='Validada' || fichainstrumento.estado === 'Bloqueada'"
                                                                    />
                                                                     <!-- v-on:blur="updateElemento(elemento)" -->
                                                                </span>
                                                                <br>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <!-- </div>
                        </div> -->
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="row" v-if="fichainstrumento.ficha__tipo_ficha==='Diseño'">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb mb-0">
                                            <li class="breadcrumb-item active" aria-current="page">Ficha de Diseño</li>
                                            <li class="breadcrumb-item"><router-link :to="'/fichaindicadores/' + fichaId + '/' + instrumentoId + '/' + anio">Indicadores</router-link></li>
                                            <li class="breadcrumb-item"><router-link :to="'/fichateoriacambio/' + fichaId + '/' + instrumentoId + '/' + anio">Teoría de Cambio</router-link></li>
                                        </ol>
                                    </nav>
                                </div>
                                <div class="section-elements">
                                    <h2 class="card-title">Información adicional</h2>
                                    <p class="mb-3"><label>Estado de la ficha:</label>
                                        <span :class="{'badge bg-validada': fichainstrumento.estado === 'Validada', 'badge bg-bloqueada': fichainstrumento.estado === 'Bloqueada'}" v-if="['Validada', 'Bloqueada'].includes(fichainstrumento.estado) && !isAdmin">
                                            {{fichainstrumento.estado}}
                                        </span>
                                        <select :disabled="!isAdmin" v-model="fichainstrumento.estado" class="form-control" v-if="isAdmin || !['Validada', 'Bloqueada'].includes(fichainstrumento.estado)">
                                            <option v-for="estado in estados" v-bind:key="estado" :value="estado" :disabled="(!isAdmin && estado==='Validada') || (!isAdmin && estado==='Bloqueada')">{{estado}}</option>
                                        </select>
                                    </p>
                                    <div class="form-check" v-if="isAdmin">
                                        <!--<input class="form-check-input" type="checkbox" v-model="fichainstrumento.validado_revisor" @click="fichainstrumento.validado_revisor=!fichainstrumento.validado_revisor" :disabled="!isAdminAgencia" />-->
                                        <input class="form-check-input" type="checkbox" v-model="fichainstrumento.validado_revisor" @click="fichainstrumento.validado_revisor=!fichainstrumento.validado_revisor" :disabled="!isAdmin" />
                                        <label class="form-check-label">
                                        ¿Validado por Encargado Agencia / Encargado Subdirección(Gerencia)?
                                        </label>
                                    </div>
                                    <div class="row" v-if="fichainstrumento.ficha__tiene_archivos">
                                        <div class="col-md-12 text-right">
                                            <!--<label>Descripción general de los archivos:</label>-->
                                            <p style="text-align: justify;">{{fichainstrumento.ficha__descripcion_archivos}}</p>
                                            <modal-archivos :ficha="fichaId" :instrumento="instrumentoId" :isAdmin="isAdmin" :estadoFicha="fichainstrumento.estado"></modal-archivos>
                                        </div>
                                    </div>
                                    <div class="btns btns--right">
                                            <a @click="updateAllElemento(fichainstrumento)" class="btn btn-primary" v-if="fichainstrumento.estado_ficha_instrumento!=='Validada' || fichainstrumento.estado_ficha_instrumento!=='Bloqueada' || isAdmin"><font-awesome-icon :icon="['fas', 'check']"/> Guardar</a>
                                    </div>
                                    <br/>
                                    <div class="btns btns--right" v-if="!isAdmin && fichainstrumento.estado==='En Proceso'">
                                            <a @click="enviarAlert(fichainstrumento)" class="btn btn-secondarytwo"><font-awesome-icon :icon="['fas', 'paper-plane']"/> Enviar</a>
                                    </div>
                                    <div class="btns btns--right" v-else>
                                            <a class="btn btn-secondarytwo_disabled"><font-awesome-icon :icon="['fas', 'paper-plane']"/> Enviar</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import ModalComentarios from '@/components/ModalComentarios.vue'
import ModalArchivos from '@/components/ModalArchivos.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'

const FichaInstrumento = resource('/ficha_instrumentos/api/ficha_instrumento', axios)
const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)

export default {
    name: 'FichaInstrumento',
    components: {
        ckeditor: CKEditor.component,
        ModalComentarios,
        ModalArchivos,
        Breadcrumb
    },
    data () {
        return {
            fichainstrumento: {},
            formAnio: {},
            fichaId: this.$route.params.fichaId,
            instrumentoId: this.$route.params.instrumentoId,
            anio: this.$route.params.anio,
            editor: ClassicEditor,
            usuario: {},
            isAdmin: false,
            isAdminAgencia: false,
            editorConfig: {
                toolbar: ['bold', 'italic', '|', 'undo', 'redo'],
                language: 'es'
            },
            estados: [
                'En Proceso',
                'Por Validar',
                'Validada',
                'Bloqueada'
            ],
            show: true
        }
    },
    methods: {
        getData () {
            FichaInstrumento.query({ ficha: this.fichaId, instrumento: this.instrumentoId, anio: this.anio }).then((res) => {
                this.fichainstrumento = JSON.parse(JSON.stringify(res.data))[0]
                // console.log(this.fichainstrumento)
                this.show = false
            }, (error) => {
                this.show = false
                console.log(error)
            })
        },
        enviarAlert (ficha) {
            this.$swal({
                title: 'Vas a enviar la ficha "' + ficha.ficha__nombre + '"',
                text: 'Enviar la ficha implica que sus contenidos están validados por el/la encargado/a de la Agencia, Subdirección o Gerencia',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    // this.fichainstrumento.validado_revisor = true
                    // this.fichainstrumento.estado = 'Por Validar'
                    ficha.validado_revisor = true
                    ficha.estado = 'Por Validar'
                    // this.enviarElemento(ficha)
                    this.updateAllElemento(ficha)
                }
            })
        },
        enviarElemento (ficha) {
            if (ficha) {
                // console.log(ficha.estado)
                // console.log(ficha.validado_revisor)
                this.updateAllElemento(ficha)
            }
        },
        updateElemento (elemento) {
            if (elemento) {
                this.show = true
                axios({
                    method: 'put',
                    url: '/ficha_instrumentos/api/editar_elemento/' + elemento.id + '/',
                    data: elemento
                })
                    .then((response) => {
                        this.show = false
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            title: 'Elemento guardado exitosamente'
                        })
                    })
                    .catch((response) => {
                        this.show = false
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Error al guardar el Elemento'
                        })
                        console.log(response)
                    })
            }
        },
        updateAllElemento (fichainstrumento) {
            var elementos = []
            var fichaInstrumentoDict = {
                method: 'put',
                url: '/ficha_instrumentos/api/ficha_instrumento/' + fichainstrumento.id + '/',
                data: fichainstrumento
            }

            elementos.push(axios(fichaInstrumentoDict))
            fichainstrumento.respuestas.forEach(elemento => {
                var elementoDict = {}
                elementoDict = {
                    method: 'put',
                    url: '/ficha_instrumentos/api/editar_elemento/' + elemento.id + '/',
                    data: elemento
                }
                elementos.push(axios(elementoDict))
            })
            if (elementos.length > 0) {
                this.show = true
                axios.all(elementos)
                    .then((response) => {
                        this.show = false
                        this.getData()
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            title: 'Elementos guardado exitosamente'
                        })
                    })
                    .catch((response) => {
                        this.show = false
                        var text = 'Error al guardar el Elemento'
                        if (response.response.status === 401) text = 'No posees permisos para realizar esta acción'
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: text
                        })
                        console.log(response)
                    })
            }
        },
        inhabilitarFichaInstrumento (fichainstrumento) {
            if (fichainstrumento) {
                this.show = true
                axios({
                    method: 'put',
                    url: '/ficha_instrumentos/api/ficha_instrumento/' + fichainstrumento.id + '/inhabilitar/'
                })
                    .then((response) => {
                        this.show = false
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            title: 'Ficha guardada exitosamente'
                        })
                        this.getData()
                    })
                    .catch((response) => {
                        this.show = false
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Error al guardada la Ficha'
                        })
                        console.log(response)
                    })
            }
        },
        closeAlert (event) {
            // console.log('unload')
            event.preventDefault()
            event.returnValue = ''
        }
    },
    mounted () {
        this.getData()
        UsuarioActual.query({}).then((res) => {
            this.usuario = res.data[0]
            if (this.usuario.tipo_usuario === 'Administrador' || this.usuario.tipo_usuario === 'Revisor Ministerio' || this.usuario.is_superuser) {
                this.isAdmin = true
            }
            if (this.usuario.tipo_usuario === 'Encargado Agencia' || this.usuario.tipo_usuario === 'Encargado Subdirección' || this.usuario.is_superuser) {
                this.isAdminAgencia = true
            }
        })
    },
    computed: {
        urlDescarga () {
            return process.env.VUE_APP_BASEURL + '/ficha_instrumentos/api/pdf_ficha_instrumento/' + this.fichaId + '/' + this.instrumentoId + '/'
        }
    },
    created () {
        window.addEventListener('beforeunload', this.closeAlert)
    }
}
</script>
