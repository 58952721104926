<template>
    <div>
        <Breadcrumb nombreActual="Material de Interés"></Breadcrumb>
        <b-modal id="modal-cargar-planillas" size="lg" title="Cargar Material de Interés">
            <div class="row justify-content-center">
                <div class="form-group col-md-5">
                    <label>Seleccione año correspondiente</label>
                    <v-select :options="anios" v-model="formSearch.anio" label="anio" :reduce="anio => anio.anio" placeholder="Año Material" class="mb-2"></v-select>
                </div>
                <div class="form-group col-md-10">
                    <label>Material (PDF)*</label>
                    <b-form-file multiple :accept="acceptedFiles" v-model="files" drop-placeholder="Deja aquí los documentos" placeholder="No hay documentos seleccionados" ref="files">
                       <template slot="file-name" slot-scope="{ names }">
                             <b-badge variant="light">{{ names[0] }}</b-badge>
                             <b-badge v-if="names && names.length > 1" variant="light" class="ml-1">
                               + {{ names.length - 1 }}
                             </b-badge>
                       </template>
                    </b-form-file>
                </div>
            </div>
            <template #modal-footer>
                <b-button size="md" variant="outline-secondary" @click="closeModal()">
                    Cancelar
                </b-button>
                <b-button @click="uploadMaterial()" class="btn btn-primary" :disabled="files.length===0">Guardar <font-awesome-icon :icon="['fas', 'arrow-right']" /></b-button>
            </template>
        </b-modal>
        <section class="ficha">
            <div class="container2">
                <div class="row justify-content-center">
                    <!-- v-for material in materiales -->
                    <div class="col-4">
                        <div class="card-r">
                            <div class="card-r-title">
                                Material
                            </div>
                            <div class="card-r-subtitle">
                                Documento Metodológico Ciclo de Aprendizaje
                            </div>
                            <div class="card-r-text">
                                Documento que describe el sistema de diseño, monitoreo y evaluación de los programas e instrumentos en materias de Ciencia, Tecnología, Conocimiento e Innovación que implementa la Oficina de Estudios y Estadísticas del Ministerio de Ciencia.
                            </div>
                            <div class="card-r-btn">
                                <a class="btn btn-primary" href="https://api.observa.minciencia.gob.cl/api/galeria/13/archivo/" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card-r">
                            <div class="card-r-title">
                                Material
                            </div>
                            <div class="card-r-subtitle">
                                Manual de Uso de Intranet
                            </div>
                            <div class="card-r-text">
                                Documento que describe los diferentes espacios de trabajo de la plataforma diseñada para implementar el Ciclo de Aprendizaje para la evaluación de instrumentos y prigramas en materias de Ciencia, Tecnología, Conocimiento e Innovación.
                            </div>
                            <div class="card-r-btn">
                                <a class="btn btn-primary" href="https://api.observa.minciencia.gob.cl/api/galeria/12/archivo/" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card-r">
                            <div class="card-r-title">
                                Material
                            </div>
                            <div class="card-r-subtitle">
                                Capacitación del Ciclo e Intranet
                            </div>
                            <div class="card-r-text">
                                 Presentación de capacitación del Ciclo de Aprendizaje y su implementación a través de la intranet. En ella podrás encontrar link al video de la capacitación.
                            </div>
                            <div class="card-r-btn">
                                <a class="btn btn-primary" href="https://api.observa.minciencia.gob.cl/api/galeria/11/archivo/" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="material_agencia">
                        <div class="row justify-content-center">
                            <div class="col-md-12" id="normal_title">
                                MATERIAL ESPECÍFICO
                            </div>
                            <div class="w-100"></div>
                            <div class="material_todos">
                                <div class="row justify-content-center align-items-center">
                                    <div class="col-md-2" v-if="isAdmin">
                                        <div class="seleccioneAgencia">Seleccione Agencia:</div>
                                    </div>
                                    <div class="col-md-5" v-if="isAdmin">
                                        <v-select :options="listAgencia" v-model="formSearch.agencia" label="nombre" :reduce="nombre => nombre.nombre" placeholder="Agencia" class="mb-2"></v-select>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="seleccioneAgencia">Seleccione año:</div>
                                    </div>
                                    <div class="col-md-2">
                                        <v-select :options="anios" v-model="formSearch.anio" label="anio" :reduce="anio => anio.anio" placeholder="Año Material" class="mb-2"></v-select>
                                    </div>
                                        <!--
                                    <div class="col-md-3">
                                        <v-select :options="listSubdireccion" v-model="formSearch.subdireccion" label="nombre" :reduce="nombre => nombre.nombre" placeholder="Subdirección / Gerencia" class="mb-2">
                                            <span slot="no-options">Seleccione una Agencia Primero.</span>
                                        </v-select>
                                    </div>-->
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div class="row">
                            <div class="col-md-auto" id="material_guia">
                                <span class="normalag">Agencia: </span>{{formSearch.agencia}}
                            </div>
                            <div class="col-md-2" id="material_guia">
                                <span class="normalag">Año: </span>{{formSearch.anio}}
                            </div>
                        </div>
                        <!--<div class="row justify-content-center" id="myinfo" v-if="agenciaUrl && formSearch.anio">-->
                        <div class="row" id="myinfo" v-if="agenciaUrl && formSearch.anio">
                            <div class="col h-100" id="material_card">
                                <a class="material_card_title" data-toggle="collapse" href="#colab" role="button" aria-expanded="false" aria-controls="colab" data-parent="#myinfo">
                                    Convenio de colaboración
                                </a>
                                <div class="collapse" id="colab" data-parent="#myinfo">
                                    <div class="material_card_info">
                                        <table class="table table-stripped table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <div class="material_card_list" v-if="isAdmin">
                                                        <span class="sub_card_text">Subdirección: </span>Todas
                                                        <br/>
                                                        <br/>
                                                        <div class="row justify-content-center no-gutters">
                                                            <div class="col-5 justify-content-center">
                                                                <a @click="openModal('', 'colaboracion')" class="material_subir">
                                                                    <font-awesome-icon :icon="['fas', 'file-upload']" /> Cargar Material
                                                                </a>
                                                            </div>
                                                            <!--<div class="col-5 justify-content-center" v-if="existentes[agenciaUrl].colaboracion">-->
                                                            <div class="col-5 justify-content-center">
                                                                <a v-if="existentes[agenciaUrl].colaboracion" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/colaboracion.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                                <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="material_card_list" v-else>
                                                        <span class="sub_card_text">Subdirección: </span>Todas
                                                        <br/>
                                                        <a v-if="existentes[agenciaUrl].colaboracion" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/colaboracion.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                        <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                    </div>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col h-100" id="material_card">
                                <a class="material_card_title" data-toggle="collapse" href="#desem" role="button" aria-expanded="false" aria-controls="desem">
                                    Convenio de desempeño FICYT
                                </a>
                                <div class="collapse" id="desem"  data-parent="#myinfo">
                                    <div class="material_card_info" v-if="agenciaUrl === 'corfo'">
                                        <table class="table table-stripped table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <div class="material_card_list" v-if="isAdmin">
                                                        <span class="sub_card_text">Subdirección: </span>Innova
                                                        <br/>
                                                        <br/>
                                                        <div class="row justify-content-center no-gutters">
                                                            <div class="col-5 justify-content-center">
                                                                <a @click="openModal('inn','desempeno')" class="material_subir">
                                                                    <font-awesome-icon :icon="['fas', 'file-upload']" /> Cargar Material
                                                                </a>
                                                            </div>
                                                            <div class="col-5 justify-content-center">
                                                                <a v-if="existentes[agenciaUrl].inn.desempeno" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/inn/desempeno.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                                <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="material_card_list" v-else>
                                                        <span class="sub_card_text">Subdirección: </span>Innova
                                                        <br/>
                                                        <a v-if="existentes[agenciaUrl].inn.desempeno" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/inn/desempeno.pdf'" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                        <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                    </div>
                                                </tr>
                                                <tr>
                                                    <div class="material_card_list" v-if="isAdmin">
                                                        <span class="sub_card_text">Subdirección: </span>Gerencia de Capacidades Tecnológicas
                                                        <br/>
                                                        <br/>
                                                        <div class="row justify-content-center no-gutters">
                                                            <div class="col-5 justify-content-center">
                                                                <a @click="openModal('gct', 'desempeno')" class="material_subir">
                                                                    <font-awesome-icon :icon="['fas', 'file-upload']" /> Cargar Material
                                                                </a>
                                                            </div>
                                                            <div class="col-5 justify-content-center">
                                                                <a v-if="existentes[agenciaUrl].gct.desempeno" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/gct/desempeno.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                                <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="material_card_list" v-else>
                                                        <span class="sub_card_text">Subdirección: </span>Gerencia de Capacidades Tecnológicas
                                                        <br/>
                                                        <a v-if="existentes[agenciaUrl].gct.desempeno" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/gct/desempeno'" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                        <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                    </div>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="material_card_info" v-else>
                                        <table class="table table-stripped table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <div class="material_card_list" v-if="isAdmin">
                                                        <span class="sub_card_text">Subdirección: </span>Todas
                                                        <br/>
                                                        <br/>
                                                        <div class="row justify-content-center no-gutters">
                                                            <div class="col-5 justify-content-center">
                                                                <a @click="openModal('', 'desempeno')" class="material_subir">
                                                                    <font-awesome-icon :icon="['fas', 'file-upload']" /> Cargar Material
                                                                </a>
                                                            </div>
                                                            <div class="col-5 justify-content-center">
                                                                <a v-if="existentes[agenciaUrl].desempeno" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/desempeno.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                                <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="material_card_list" v-else>
                                                        <span class="sub_card_text">Subdirección: </span>Todas
                                                        <br/>
                                                        <a v-if="existentes[agenciaUrl].desempeno" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/desempeno.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                        <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                    </div>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col h-100" id="material_card">
                                <a class="material_card_title" data-toggle="collapse" href="#ins" role="button" aria-expanded="false" aria-controls="ins">
                                    Instructivo CDA
                                </a>
                                <div class="collapse" id="ins"  data-parent="#myinfo">
                                    <div class="material_card_info" v-if="agenciaUrl === 'corfo'">
                                        <table class="table table-stripped table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <div class="material_card_list" v-if="isAdmin">
                                                        <span class="sub_card_text">Subdirección: </span>Innova
                                                        <br/>
                                                        <br/>
                                                        <div class="row justify-content-center no-gutters">
                                                            <div class="col-5 justify-content-center">
                                                                <a @click="openModal('inn', 'cda')" class="material_subir">
                                                                    <font-awesome-icon :icon="['fas', 'file-upload']" /> Cargar Material
                                                                </a>
                                                            </div>
                                                            <div class="col-5 justify-content-center">
                                                                <a v-if="existentes[agenciaUrl].inn.cda" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/inn/cda.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                                <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="material_card_list" v-else>
                                                        <span class="sub_card_text">Subdirección: </span>Innova
                                                        <br/>
                                                        <a v-if="existentes[agenciaUrl].inn.cda" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/inn/cda.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                        <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                    </div>
                                                </tr>
                                                <tr>
                                                    <div class="material_card_list" v-if="isAdmin">
                                                        <span class="sub_card_text">Subdirección: </span>Gerencia de Capacidades Tecnológicas
                                                        <br/>
                                                        <br/>
                                                        <div class="row justify-content-center no-gutters">
                                                            <div class="col-5 justify-content-center">
                                                                <a @click="openModal('gct', 'cda')" class="material_subir">
                                                                    <font-awesome-icon :icon="['fas', 'file-upload']" /> Cargar Material
                                                                </a>
                                                            </div>
                                                            <div class="col-5 justify-content-center">
                                                                <a v-if="existentes[agenciaUrl].gct.cda" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/gct/cda.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                                <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="material_card_list" v-else>
                                                        <span class="sub_card_text">Subdirección: </span>Gerencia de Capacidades Tecnológicas
                                                        <br/>
                                                        <a v-if="existentes[agenciaUrl].gct.cda" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/gct/cda'" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                        <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                    </div>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="material_card_info" v-else>
                                        <table class="table table-stripped table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <div class="material_card_list" v-if="isAdmin">
                                                        <span class="sub_card_text">Subdirección: </span>Todas
                                                        <br/>
                                                        <br/>
                                                        <div class="row justify-content-center no-gutters">
                                                            <div class="col-5 justify-content-center">
                                                                <a @click="openModal('', 'cda')" class="material_subir">
                                                                    <font-awesome-icon :icon="['fas', 'file-upload']" /> Cargar Material
                                                                </a>
                                                            </div>
                                                            <div class="col-5 justify-content-center">
                                                                <a v-if="existentes[agenciaUrl].cda" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/cda.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                                <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="material_card_list" v-else>
                                                        <span class="sub_card_text">Subdirección: </span>Todas
                                                        <br/>
                                                        <a v-if="existentes[agenciaUrl].cda" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/cda.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                        <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                    </div>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100"></div>
                            <div class="col h-100" id="material_card">
                                <a class="material_card_title" data-toggle="collapse" href="#guia" role="button" aria-expanded="false" aria-controls="guia">
                                    Guía de transferencia SFTP
                                </a>
                                <div class="collapse" id="guia"  data-parent="#myinfo">
                                    <div class="material_card_info">
                                        <table class="table table-stripped table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <div class="material_card_list" v-if="isAdmin">
                                                        <span class="sub_card_text">Subdirección: </span>Todas
                                                        <br/>
                                                        <br/>
                                                        <div class="row justify-content-center no-gutters">
                                                            <div class="col-5 justify-content-center">
                                                                <a @click="openModal('', 'sftp')" class="material_subir">
                                                                    <font-awesome-icon :icon="['fas', 'file-upload']" /> Cargar Material
                                                                </a>
                                                            </div>
                                                            <div class="col-5 justify-content-center">
                                                                <a v-if="existentes[agenciaUrl].sftp" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/sftp.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                                <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="material_card_list" v-else>
                                                        <span class="sub_card_text">Subdirección: </span>Todas
                                                        <br/>
                                                        <a v-if="existentes[agenciaUrl].sftp" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/sftp.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                        <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                    </div>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col h-100" id="material_card">
                                <a class="material_card_title" data-toggle="collapse" href="#pro" role="button" aria-expanded="false" aria-controls="pro">
                                    Protocolos
                                </a>
                                <div class="collapse" id="pro" data-parent="#myinfo">
                                    <div class="material_card_info">
                                        <table class="table table-stripped table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <div class="material_card_list" v-if="isAdmin">
                                                        <span class="sub_card_text">Subdirección: </span>Todas
                                                        <br/>
                                                        <br/>
                                                        <div class="row justify-content-center no-gutters">
                                                            <div class="col-5 justify-content-center">
                                                                <a @click="openModal('', 'protocolo')" class="material_subir">
                                                                    <font-awesome-icon :icon="['fas', 'file-upload']" /> Cargar Material
                                                                </a>
                                                            </div>
                                                            <div class="col-5 justify-content-center">
                                                                <a v-if="existentes[agenciaUrl].protocolo" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/protocolo.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                                <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="material_card_list" v-else>
                                                        <span class="sub_card_text">Subdirección: </span>Todas
                                                        <br/>
                                                        <a v-if="existentes[agenciaUrl].protocolo" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/protocolo.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                        <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                    </div>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col h-100" id="material_card">
                                <a class="material_card_title" data-toggle="collapse" href="#oficio" role="button" aria-expanded="false" aria-controls="oficio">
                                    Oficio
                                </a>
                                <div class="collapse" id="oficio"  data-parent="#myinfo">
                                    <div class="material_card_info">
                                        <table class="table table-stripped table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <div class="material_card_list" v-if="isAdmin">
                                                        <span class="sub_card_text">Subdirección: </span>Todas
                                                        <br/>
                                                        <br/>
                                                        <div class="row justify-content-center no-gutters">
                                                            <div class="col-5 justify-content-center">
                                                                <a @click="openModal('', 'oficio')" class="material_subir">
                                                                    <font-awesome-icon :icon="['fas', 'file-upload']" /> Cargar Material
                                                                </a>
                                                            </div>
                                                            <div class="col-5 justify-content-center">
                                                                <a v-if="existentes[agenciaUrl].oficio" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/oficio.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                                <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="material_card_list" v-else>
                                                        <span class="sub_card_text">Subdirección: </span>Todas
                                                        <br/>
                                                        <a v-if="existentes[agenciaUrl].oficio" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/oficio.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                        <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                    </div>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100"></div>
                            <div class="col-md-3-5 h-100" id="material_card">
                                <a class="material_card_title" data-toggle="collapse" href="#otro" role="button" aria-expanded="false" aria-controls="otro">
                                    Otros
                                </a>
                                <div class="collapse" id="otro"  data-parent="#myinfo">
                                    <div class="material_card_info">
                                        <table class="table table-stripped table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <div class="material_card_list" v-if="isAdmin">
                                                        <span class="sub_card_text">Subdirección: </span>Todas
                                                        <br/>
                                                        <br/>
                                                        <div class="row justify-content-center no-gutters">
                                                            <div class="col-5 justify-content-center">
                                                                <a @click="openModal('', 'otro')" class="material_subir">
                                                                    <font-awesome-icon :icon="['fas', 'file-upload']" /> Cargar Material
                                                                </a>
                                                            </div>
                                                            <div class="col-5 justify-content-center">
                                                                <a v-if="existentes[agenciaUrl].otro" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/otro.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                                <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="material_card_list" v-else>
                                                        <span class="sub_card_text">Subdirección: </span>Todas
                                                        <br/>
                                                        <a v-if="existentes[agenciaUrl].otro" class="material_descargar" :href="baseUrl + anioUrl + agenciaUrl + '/otro.pdf'"  target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                                                        <a v-else class="material_no_descargar" target="_blank"><font-awesome-icon :icon="['fas', 'file-excel']" /> Sin Material</a>
                                                    </div>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="notice_agencia" v-if="!agenciaUrl && formSearch.anio">
                            No ha seleccionado ninguna agencia para agregar material
                        </div>
                        <div class="notice_agencia" v-if="agenciaUrl && !formSearch.anio">
                            No ha seleccionado ningun año para descargar material
                        </div>
                        <div class="notice_agencia" v-if="!agenciaUrl && !formSearch.anio">
                            No ha seleccionado ninguna agencia y ningún año para agregar material
                        </div>
                        <!--<div class="row justify-content-center">
                        </div>-->
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'
import Breadcrumb from '@/components/Breadcrumb.vue'

const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)
const ListarAgencia = resource('/api/api/listar_agencia', axios)
const ListarSubdireccion = resource('/api/api/listar_subdireccion', axios)
const Material = resource('/api/api/subir_material/', axios)
const Anio = resource('/ficha_instrumentos/api/anios_ficha_instrumento', axios)

export default {
    name: 'Reportes',
    components: {
        Breadcrumb
    },
    async beforeCreate () {

    },
    data () {
        return {
            formSearch: { agencia: null, subdireccion: null, anio: null },
            listAgencia: [],
            listSubdireccion: [],
            isAdmin: false,
            directorio: {
                ANID: 'anid',
                CORFO: 'corfo',
                FIA: 'fia',
                'Subsecretaría de Ciencia, Tecnología, Conocimiento e Innovación': 'cyc',
                'Gerencia de Capacidades Tecnológicas': 'gct',
                Innova: 'inn',
                'Agencia Test': 'agtest'
            },
            t_material: '',
            baseUrl: 'https://api.dev.cda.minciencia.gob.cl/media/material/',
            // baseUrl: 'http://localhost:8000/media/material/',
            agenciaUrl: '',
            anioUrl: '',
            isAdminAgencia: false,
            files: [],
            sub: '',
            ruta: '',
            acceptedFiles: 'application/pdf',
            existentes: {
                anid: {
                    colaboracion: false,
                    desempeno: false,
                    cda: false,
                    sftp: false,
                    protocolo: false,
                    oficio: false
                },
                corfo: {
                    colaboracion: false,
                    desempeno: false,
                    cda: false,
                    sftp: false,
                    protocolo: false,
                    oficio: false,
                    inn: {
                        colaboracion: false,
                        desempeno: false,
                        cda: false,
                        sftp: false,
                        protocolo: false,
                        oficio: false
                    },
                    gct: {
                        colaboracion: false,
                        desempeno: false,
                        cda: false,
                        sftp: false,
                        protocolo: false,
                        oficio: false
                    }
                },
                fia: {
                    colaboracion: false,
                    desempeno: false,
                    cda: false,
                    ftp: false,
                    protocolo: false,
                    oficio: false
                },
                cyc: {
                    colaboracion: false,
                    desempeno: false,
                    cda: false,
                    sftp: false,
                    protocolo: false,
                    oficio: false
                }
            },
            anios: []
        }
    },
    methods: {
        getListAgencia () {
            ListarAgencia.query({ }).then((res) => {
                this.listAgencia = res.data
            })
        },
        getListSubdireccion () {
            ListarSubdireccion.query({ agencia: this.formSearch.agencia }).then((res) => {
                this.listSubdireccion = res.data
            })
        },
        getListMaterial () {
            if (this.formSearch.anio) {
                Material.query({ anio: this.formSearch.anio }).then((res) => {
                    // console.log(res.data)
                    this.existentes = res.data
                })
            }
        },
        getAnio () {
            Anio.query({ ficha__tipo_ficha: 'Diseño' }).then((res) => {
                this.anios = res.data
                // console.log(this.anios)
                // console.log(typeof (this.anios[0].anio))
                this.formSearch.anio = this.anios.at(-1).anio
                this.anioUrl = this.formSearch.anio + '/'
            })
        },
        openModal (sub, tipo) {
            this.t_material = tipo
            if (sub.length > 0) {
                this.sub = '-' + sub
            } else this.sub = ''
            // console.log(this.t_material)
            if (this.agenciaUrl.length <= 0) {
                this.$notify({
                    group: 'foo',
                    type: 'error',
                    title: 'Selecciona una Agencia o Subdirección Primero'
                })
            } else {
                this.$root.$emit('bv::show::modal', 'modal-cargar-planillas')
            }
        },
        closeModal () {
            // console.log(typeof (this.formSearch.anio))
            this.getListMaterial()
            this.$root.$emit('bv::hide::modal', 'modal-cargar-planillas')
        },
        uploadMaterial () {
            if (!this.formSearch.anio) {
                this.$notify({
                    group: 'foo',
                    type: 'error',
                    title: 'Seleccione un año primero'
                })
            } else {
                var formData = new FormData()
                // console.log(this.files[0])
                formData.append('file', this.files[0])
                this.show = true
                // this.ruta = this.agenciaUrl + this.sub + '/' + this.t_material
                this.ruta = this.agenciaUrl + this.sub + '/' + this.t_material + '/' + this.formSearch.anio
                // console.log(this.ruta)
                // axios.post('/monitoreo/api/cargar_planilla_respuesta/',
                axios.post('/api/api/subir_material/' + this.ruta,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((response) => {
                        this.show = false
                        // this.getData()
                        this.closeModal()
                        this.files = []
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            title: 'Material subido exitosamente'
                        })
                    })
                    .catch((response) => {
                        this.show = false
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Error al subir material'
                        })
                    })
            }
        }
        /* getData () {
            FichaTodas.query({ ficha__tipo_ficha: 'Diseño', instrumento: this.formSearch.instrumento, instrumento__subdireccion: this.formSearch.subdireccion, instrumento__subdireccion__agencia: this.formSearch.agencia, anio: this.formSearch.anio }).then((res) => {
                this.fichas = res.data
                this.rows = res.data.length
                this.original_length = res.data.length
                this.show = false
            }, function (error) {
                this.show = false
                console.log(error)
            })
        } */
    },
    mounted () {
        this.getListAgencia()
        this.getAnio()
        this.getListMaterial()
        UsuarioActual.query({}).then((res) => {
            this.usuario = res.data[0]
            if (this.usuario.tipo_usuario === 'Administrador' || this.usuario.tipo_usuario === 'Revisor Ministerio' || this.usuario.is_superuser) {
                // console.log(this.usuario)
                this.isAdmin = true
            }
            if (this.usuario.tipo_usuario === 'Encargado Agencia' || this.usuario.tipo_usuario === 'Encargado Subdirección' || this.usuario.is_superuser) {
                this.agenciaUrl = this.directorio[this.usuario.agencia__nombre]
                // console.log(this.agenciaUrl)
                this.formSearch.agencia = this.usuario.agencia__nombre
                this.isAdminAgencia = true
            }
        })
    },
    computed: {
    },
    watch: {
        'formSearch.agencia': {
            deep: true,
            handler: function (newVal) {
                if (newVal !== null) {
                    this.agenciaUrl = this.directorio[newVal]
                    this.formSearch.subdireccion = null
                    // console.log(this.agenciaUrl)
                    // console.log(this.existentes[this.agenciaUrl])
                    // this.getListSubdireccion()
                } else {
                    this.agenciaUrl = ''
                    this.listSubdireccion = []
                    this.formSearch.subdireccion = null
                }
            }
        },
        'formSearch.anio': {
            deep: true,
            handler: function (newVal) {
                this.getListMaterial()
                this.anioUrl = newVal + '/'
            }
        }
    }
}
</script>
