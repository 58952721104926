<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <div class="row">
            <div class="col-md-7">
                <Breadcrumb nombreActual="Gestor de Monitoreos" nombreAnterior="Panel de Administración" urlAnterior="/panel_administracion"></Breadcrumb>
            </div>
        </div>
        <section class="ficha">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="section__container">
                            <div class="card card--section">
                                <div class="card-header">
                                    <h2 class="card-title d-inline-block">Gestor de Monitoreos</h2>
                                    <router-link to="/crear_monitoreo" class="btn btn-primary float-right">
                                        <font-awesome-icon :icon="['fas', 'plus']" /> Crear Monitoreo
                                    </router-link>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            Filtrar por:
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <v-select :options="tipos" v-model="formSearch.tipo" label="tipo" placeholder="Tipo de Monitoreo" class="mb-2"></v-select>
                                        </div>
                                        <div class="col-md-3">
                                            <v-select :options="anios" v-model="formSearch.anio" label="anio" :reduce="anio => anio.anio" placeholder="Año de monitoreo" class="mb-2"></v-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="alert alert-info" role="alert" v-if="monitoreos.length === 0">
                                        No hay información disponible
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-stripped table-hover table-bordered" id="tabla-monitoreos" v-if="monitoreos.length > 0">
                                            <thead>
                                                <tr>
                                                    <td>Nombre</td>
                                                    <td>Año</td>
                                                    <td>Descripción</td>
                                                    <td>Tipo</td>
                                                    <td>Estado</td>
                                                    <td>Fecha Creación</td>
                                                    <td>Última Actualización</td>
                                                    <td>Acción</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="monitoreo in monitoreos" v-bind:key="monitoreo.id">
                                                    <td>{{monitoreo.nombre}}</td>
                                                    <td>{{monitoreo.anio}}</td>
                                                    <td>{{monitoreo.descripcion}}</td>
                                                    <td>{{monitoreo.tipo_monitoreo}}</td>
                                                    <td><span class="badge" v-bind:class="{ 'bg-por-validar': monitoreo.estado ==='Por Publicar', 'bg-validada': monitoreo.estado === 'Publicada'}">{{monitoreo.estado}}</span></td>
                                                    <td>{{monitoreo.fecha_creacion | dateFormat}}</td>
                                                    <td>{{monitoreo.fecha_actualizacion | dateTimeFormat}}</td>
                                                    <td>
                                                        <div class="btns">
                                                            <router-link :to="'/crear_monitoreo/' + monitoreo.id" class="btn btn-secondary btn-sm"><font-awesome-icon :icon="['fas', 'edit']" /> Editar Monitoreo</router-link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="rows"
                                        :per-page="perPage"
                                        aria-controls="tabla-monitoreos"
                                    ></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import Breadcrumb from '@/components/Breadcrumb.vue'

const Monitoreo = resource('/monitoreo/api/crear_monitoreo', axios)
const Anio = resource('/monitoreo/api/ver_anio_monitoreo', axios)

export default {
    name: 'ListarMonitoreos',
    components: {
        Breadcrumb
    },
    data () {
        return {
            monitoreos: [],
            currentPage: 1,
            perPage: null,
            rows: null,
            page: null,
            formSearch: {},
            anios: [],
            tipos: ['Productos y Resultados', 'Procesos'],
            show: true
        }
    },
    methods: {
        getAnio () {
            Anio.query({ }).then((res) => {
                this.anios = res.data
            })
        },
        getData () {
            Monitoreo.query({ page: this.currentPage, per_page: 10, tipo_monitoreo: this.formSearch.tipo, anio: this.formSearch.anio }).then((res) => {
                this.monitoreos = res.data.items
                this.perPage = res.data.paginate.per_page
                this.rows = res.data.paginate.count
                this.show = false
            })
                .catch((error) => {
                    console.log(error)
                    this.show = false
                })
        }
    },
    mounted () {
        this.getAnio()
        this.$watch('currentPage', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                this.getData()
            }
        }, { immediate: true })
        this.$watch('formSearch.anio', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                this.getData()
            }
        }, { immediate: true })
        this.$watch('formSearch.tipo', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                this.getData()
            }
        }, { immediate: true })
    }
}
</script>
