<template>
    <div :key="changes">
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <b-modal id='modal-create-elemento' size="lg" :title="'Elemento de: ' + titulo">
            <div class="row">
                <div class="form-group col-md-10">
                    <label>Descripción*</label>
                    <textarea class="form-control" v-model="elemento.descripcion" :disabled="estadoFicha==='Validada' || estadoFicha==='Bloqueada'"></textarea>
                </div>
                <div class="col-md-2 align-self-end">
                    <a @click="formComentario.observacion=''" class="btn btn-primary btn-sm mr-1">Limpiar</a><a href="#" v-b-tooltip.hover title="Limpiar texto que se está escribiendo"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                </div>
            </div>
            <div class="row" v-if="indicadores.length>0">
                <div class="col-md-7">
                    <label>Selecciona los indicadores que apliquen</label>
                    <v-select multiple :options="indicadores" v-model="elemento.indicadores" label="nombre" :reduce="nombre => nombre.id" :disabled="estadoFicha==='Validada' || estadoFicha==='Bloqueada'">
                        <template slot="option" slot-scope="option">
                           {{ option.nombre }} - <i>{{option.subdireccion__nombre}}</i> - <strong>{{option.agencia__nombre}}</strong>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                           {{ option.nombre }}
                        </template>
                    </v-select>
<!--                         <select multiple v-model="elemento.indicadores" class="form-control" :disabled="estadoFicha==='Validada' || estadoFicha==='Bloqueada'">
                        <option v-for="indicador in indicadores" v-bind:key="indicador.id" :value="indicador.id">{{indicador.nombre}}</option>
                    </select> -->
                </div>
                <div class="col-md-5" v-if="elemento.elementos_padres_data && elemento.elementos_padres_data.length > 0">
                    <label>Conexiones disponibles</label>
                    <div class="row">
                        <div class="col-md-12 mb-2" v-for="padre in elemento.elementos_padres_data" v-bind:key="padre.id">
                            <small class="badge bg-success">{{padre.elemento__abreviatura}}</small>
                            <a @click="removeLine(padre.id, elemento)" role="button" class="btn-icon-remove" style="right:7.85em;top:2px">Eliminar</a>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <a @click="deleteElemento(elemento)" v-if="editar && estadoFicha!=='Validada' || estadoFicha!=='Bloqueada'" class="btn btn-danger">
                    <font-awesome-icon :icon="['fas', 'trash-alt']"/> Eliminar
                </a>
                <b-button size="md" variant="outline-secondary" @click="closeModal();getData()">
                    Cancelar
                </b-button>
                <a @click="createElemento()" class="btn btn-primary" v-if="!editar && !['Validada','Bloqueada'].includes(estadoFicha)">Guardar <font-awesome-icon :icon="['fas', 'check']" /></a>
                <a @click="updateElemento(elemento)" class="btn btn-primary" v-if="editar && !['Validada','Bloqueada'].includes(estadoFicha)">Guardar <font-awesome-icon :icon="['fas', 'check']" /></a>
            </template>
        </b-modal>
        <Breadcrumb v-if="teoriaCambio.instrumento" :nombreActual="'Ficha Teoría de Cambio ' + teoriaCambio.instrumento__nombre" nombreAnterior="Fichas Diseño" urlAnterior="/fichas_diseno"></Breadcrumb>
        <section class="ficha">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <b-card class="card--section">
                            <b-card-header>
                                <div class="row">
                                    <div class="col-md-9">
                                        <h2 class="card-title mb-0">Teoría del Cambio: {{anio}} <span class="badge" v-bind:class="{ 'bg-en-proceso': estadoFicha === 'En Proceso', 'bg-por-validar': estadoFicha ==='Por Validar', 'bg-validada': estadoFicha === 'Validada', 'bg-bloqueada': estadoFicha === 'Bloqueada'}">{{estadoFicha}}</span>
                                            <span v-if="isAdmin" class="ml-2">
                                                <span class="badge badge-danger" v-if="!teoriaCambio.activo">
                                                    <font-awesome-icon :icon="['fas', 'ban']"/>
                                                    Deshabilitado
                                                </span>
                                                <span class="badge badge-success" v-if="teoriaCambio.activo">
                                                    <font-awesome-icon :icon="['fas', 'circle']"/>
                                                    Habilitado
                                                </span>
                                            </span>
                                        </h2>
                                    </div>
                                    <div class="col-md-3">
                                        <a class="btn btn-primary float-right" @click="updateAllElemento()" v-if="estadoFicha!=='Validada' || estadoFicha!=='Bloqueada'"><font-awesome-icon :icon="['fas', 'check']"/> Guardar</a>
                                    </div>
                                </div>
                                <div class="row">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><router-link :to="'/ficha/' + fichaId + '/' + instrumentoId + '/' + anio">Ficha de Diseño</router-link></li>
                                            <li class="breadcrumb-item"><router-link :to="'/fichaindicadores/' + fichaId + '/' + instrumentoId + '/' + anio">Indicadores</router-link></li>
                                            <li class="breadcrumb-item active" aria-current="page">Teoría de Cambio</li>
                                        </ol>
                                    </nav>
                                </div>
                                <p>Complete únicamente los campos que apliquen</p>
                                <!-- <a class="btn btn-primary" @click="updateAllElemento()"><font-awesome-icon :icon="['fas', 'plus']" /> elemento</a> -->
                            </b-card-header>
                            <b-card-body>
                                <ModalComentarios  v-if="teoriaCambioId" :ficha="fichaId" :instrumento="instrumentoId" :teoriaCambio="teoriaCambioId" :titulo="'Teoría de Cambio'" :modal="'teoriacambio-' + teoriaCambioId"></ModalComentarios>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label>Propósito* <a href="#" v-b-tooltip.hover title="Indicar el Propósito"><font-awesome-icon :icon="['fas', 'info-circle']" /></a></label>
                                        <textarea class="form-control" v-model="teoriaCambio.proposito" :disabled="estadoFicha==='Validada' || estadoFicha==='Bloqueada'"></textarea>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>Supuestos* (Separe con <strong>;</strong> los supuestos) <a href="#" v-b-tooltip.hover title="Indicar los Supuestos"><font-awesome-icon :icon="['fas', 'info-circle']" /></a></label>
                                        <textarea class="form-control" v-model="teoriaCambio.supuestos" :disabled="estadoFicha==='Validada' || estadoFicha==='Bloqueada'"></textarea>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>Riesgos* (Separe con <strong>;</strong> los riesgos) <a href="#" v-b-tooltip.hover title="Indicar los Riesgos Asociados"><font-awesome-icon :icon="['fas', 'info-circle']" /></a></label>
                                        <textarea class="form-control" v-model="teoriaCambio.riesgos" :disabled="estadoFicha==='Validada' || estadoFicha==='Bloqueada'"></textarea>
                                    </div>
                                    <!--<b-button @click="trythis">TEST_BORRAR</b-button>-->
                                    <!--<b-button @click="screenshot">TEST_SCREENSHOT</b-button>-->
                                </div>
<!--                                 <div class="table-legend" style="text-align: right;">
                                    <ul class="table-legend__list">
                                        <li class="table-legend__item" v-for="categoria in categorias" v-bind:key="categoria.id">
                                            <div class="table-legend__color table-legend__color--green "></div>
                                            <small class="table-legend__text"><small class="badge bg-success"><strong>{{categoria.abreviatura}}</strong></small> {{categoria.nombre}}</small>
                                        </li>
                                    </ul>
                                </div> -->
                                <div class="row" id='screenshot_this'>
                                    <div class="col-md-12">
                                        <div class="drag-container" v-drag-and-drop:options="options">
                                            <ul class="drag-list">
                                                <li class="drag-column" v-for="categoria in categorias" :key="categoria.id">
                                                    <div class="drag-column-header">
                                                        <div class="row justify-content-center" id="adjustwidth">
                                                            <div class="drag-header-text" :id="categoria.abreviatura">
                                                                <h2><!--<small class="badge bg-success float-left mr-2"><strong>{{categoria.abreviatura}}</strong></small>-->
                                                                    <span>{{ categoria.nombre }}</span>
                                                                </h2>
                                                            </div>
                                                            <div class="col-md-12" id="boton_plus">
                                                                <b-button v-if="!['Bloqueada', 'Validada'].includes(estadoFicha)" @click="openModal(categoria, {}, false)" class="btn btn-sm" v-b-tooltip.hover.right title="Agregar tipo de indicador">
                                                                    <font-awesome-icon :icon="['fas', 'plus']" />
                                                                </b-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <vue-draggable-group
                                                        v-model="categoria.elementos"
                                                        :groups="categorias"
                                                        :data-id="categoria.id"
                                                        @change="onGroupsChange"
                                                    >
                                                    <ul class="drag-inner-list" :data-id="categoria.id">

                                                        <!-- <li class="drag-item" v-for="elemento in categoria.elementos" :key="elemento.id" :data-id="elemento.id" :ref="'line' + elemento.id">
                                                            <div class="drag__drop-point" v-b-tooltip.hover.right title="Conectar">
                                                                <font-awesome-icon :icon="['fas', 'dot-circle']" />
                                                            </div> -->

                                                        <li class="drag-item" v-for="elemento in categoria.elementos" :key="elemento.id" :data-id="elemento.id" :ref="'elemento_' + elemento.id" v-bind:id="'elemento_' + elemento.id">
                                                            <!-- {{ line(elemento) }} -->
                                                            <div class="drag-item-text">
                                                                <small class="badge bg-success badge--orden">
                                                                    <strong>{{ elemento.orden }} </strong>
                                                                </small>
                                                                {{ elemento.descripcion }}
                                                            </div>
                                                            <!-- <a @click="openModal(categoria, elemento)">
                                                                <font-awesome-icon :icon="['fas', 'edit']" />
                                                            </a>
                                                            <a @click="deleteElemento(elemento)">
                                                                <font-awesome-icon :icon="['fas', 'trash-alt']" />
                                                            </a> -->
                                                            <small class="badge bg-alert ml-2 mb-2" v-if="elemento.cantidad_indicadores > 0">{{elemento.cantidad_indicadores}} Ind.</small>
                                                            <!--<small class="badge bg-success ml-2 mb-2" v-for="padre in elemento.elementos_padres_data" v-bind:key="padre.id">{{padre.elemento__abreviatura}}</small>-->
                                                            <div class="drag__options">
                                                                <a class="drag__btn--plus">
                                                                    <font-awesome-icon :icon="['fas', 'plus']" />
                                                                    <ul class="drag__options-list">
                                                                        <li class="drag__options-item">
                                                                            <a @click="openModal(categoria, elemento, true)" class="drag__options-link" v-b-tooltip.hover.right title="Editar">
                                                                                <font-awesome-icon :icon="['fas', 'edit']" />
                                                                            </a>
                                                                        </li>
                                                                        <li class="drag__options-item"  v-if="estadoFicha!=='Validada' || estadoFicha!=='Bloqueada'">
                                                                            <a class="drag__options-link" v-b-tooltip.hover.right title="Agregar conector" @click="addLine(elemento)">
                                                                                <font-awesome-icon :icon="['fas', 'project-diagram']" />
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                    <svg height="210" width="500" class="" v-for="data in elemento.data" v-bind:key="data.id">
                                                                        <line :x1="data.x1" :y1="data.y1" :x2="data.x2" :y2="data.y2" style="stroke:rgb(255,0,0);stroke-width:2" />
                                                                        Sorry, your browser does not support inline SVG.
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                            <!-- <svg height="210" width="500" class="drag__conector">
                                                                <line x1="100%" y1="0" x2="200" y2="200" style="stroke:rgb(255,0,0);stroke-width:2" />
                                                                Sorry, your browser does not support inline SVG.
                                                            </svg> -->
                                                            <!--TEST POINTS-->
                                                           <!-- <span class="btn">Randomize points</span></p> -->
                                                            <!-- <div id="one" class="point"></div>
                                                            <div id="two" class="point"></div>
                                                            <div id="three" class="point"></div>
                                                            <div id="four" class="point"></div> -->

                                                            <!-- <svg id="svg">
                                                              <line id="line" class="line original" stroke-dasharray="5, 5" x1="100%" y1="0" x2="200" y2="200" style="stroke:rgb(255,0,0);stroke-width:2" />
                                                            </svg> -->
                                                            <!--end test-->
                                                        </li>
                                                    </ul>
                                                    </vue-draggable-group>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-card>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'
import ModalComentarios from '@/components/ModalComentarios.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import LeaderLine from 'leader-line-new'

const CategoriaTdC = resource('/ficha_instrumentos/api/listar_categorias_tdc', axios)
const TeoriaCambio = resource('/ficha_instrumentos/api/editar_tdc', axios)
const Indicador = resource('/ficha_instrumentos/api/listar_indicadores', axios)
const ElementoTdC = resource('/ficha_instrumentos/api/crear_elemento_tdc/', axios)
const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)

export default {
    name: 'FichaTeoriaCambio',
    components: {
        ModalComentarios,
        Breadcrumb
    },
    data () {
        return {
            fichaId: this.$route.params.fichaId,
            instrumentoId: this.$route.params.instrumentoId,
            anio: this.$route.params.anio,
            categorias: [],
            indicadores: [],
            elementostdc: [],
            teoriaCambio: {},
            teoriaCambioId: null,
            tipoIndicadoroId: null,
            elemento: {},
            titulo: null,
            editar: false,
            options: {
                dropzoneSelector: '.drag-inner-list',
                draggableSelector: '.drag-item',
                onDragend (event) {
                    if (event.droptarget.attributes[0] !== event.owner.attributes[0]) this.getData()
                }
            },
            estadoFicha: {},
            elementoIni: null,
            elementoEnd: null,
            posIni: null,
            posEnd: null,
            isAdmin: false,
            colores: {},
            show: true,
            lineas: {},
            changes: null,
            ready: false,
            optCount: 0
        }
    },
    methods: {
        getData () {
            // this.trythis()
            TeoriaCambio.query({ instrumento: this.instrumentoId, ficha: this.fichaId }).then((res) => {
                this.teoriaCambio = res.data[0]
                this.teoriaCambioId = this.teoriaCambio.id
                this.show = false
                var supuestos = ''
                if (this.teoriaCambio.supuestos) {
                    this.teoriaCambio.supuestos.map((value, key) => {
                        supuestos += value + ';'
                    })
                    this.teoriaCambio.supuestos = supuestos
                }
                var riesgos = ''
                if (this.teoriaCambio.riesgos) {
                    this.teoriaCambio.riesgos.map((value, key) => {
                        riesgos += value + ';'
                    })
                    this.teoriaCambio.riesgos = riesgos
                }
                CategoriaTdC.query({ teoria_cambio: this.teoriaCambioId }).then((res) => {
                    this.categorias = res.data
                }, function (error) {
                    console.log(error)
                })
                ElementoTdC.query({ teoria_cambio: this.teoriaCambioId }).then((res) => {
                    // this.elementostdc = res.data
                    // console.log(res.data)
                    /* this.elementostdc.map((value, key) => {
                        // console.log(value)
                        this.line(value)
                    }) */
                    this.reDrawAll(res.data)
                    // console.log('GET HECHO')
                }, function (error) {
                    console.log(error)
                })
            }, (error) => {
                this.show = false
                console.log(error)
            })
            axios({
                method: 'put',
                url: '/ficha_instrumentos/api/ficha_instrumento/' + this.fichaId + '/estado/',
                data: { instrumento: this.instrumentoId }
            })
                .then((response) => {
                    this.estadoFicha = response.data
                })
                .catch((response) => {
                    console.log(response)
                })
        },
        createElemento () {
            this.elemento.tipo_indicador = this.tipoIndicadoroId
            this.elemento.teoria_cambio = this.teoriaCambioId
            this.elemento.data = []
            this.show = true
            axios({
                method: 'post',
                url: '/ficha_instrumentos/api/crear_elemento_tdc/',
                data: this.elemento
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.closeModal()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Elemento creado exitosamente'
                    })
                    // this.reloadPage()
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear el Elemento'
                    })
                })
        },
        // updateTdc () {
        //     this.teoriaCambio.supuestos = this.teoriaCambio.supuestos.split(';')
        //     this.teoriaCambio.riesgos = this.teoriaCambio.riesgos.split(';')
        //     axios({
        //         method: 'put',
        //         url: '/ficha_instrumentos/api/editar_tdc/' + this.teoriaCambio.id + '/',
        //         data: this.teoriaCambio
        //     })
        //         .then((response) => {
        //             this.getData()
        //             this.$notify({
        //                 group: 'foo',
        //                 type: 'success',
        //                 title: 'Teoría de Cambio actualizado exitosamente'
        //             })
        //         })
        //         .catch((response) => {
        //             this.$notify({
        //                 group: 'foo',
        //                 type: 'error',
        //                 title: 'Error al actualizar el Indicador'
        //             })
        //         })
        // },
        updateElemento (elemento) {
            // elemento.tipo_indicador = this.tipoIndicadoroId
            // elemento.teoria_cambio = this.teoriaCambioId
            this.show = true
            axios({
                method: 'put',
                url: '/ficha_instrumentos/api/crear_elemento_tdc/' + elemento.id + '/',
                data: elemento
            })
                .then((response) => {
                    this.show = false
                    this.elementoIni = null
                    this.posIni = null
                    this.posEnd = null
                    this.editar = false
                    this.elemento = {}
                    // this.trythis()
                    this.getData()
                    this.closeModal()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Elementos guardado exitosamente'
                    })
                    // this.reloadPage()
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al guardar el Elementos'
                    })
                })
        },
        deleteElemento (elemento) {
            this.show = true
            axios({
                method: 'delete',
                url: '/ficha_instrumentos/api/crear_elemento_tdc/' + elemento.id + '/',
                data: elemento
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.closeModal()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Elementos eliminado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el Elemento'
                    })
                })
        },
        updateAllElemento () {
            if (this.teoriaCambio.supuestos) this.teoriaCambio.supuestos = this.teoriaCambio.supuestos.split(';')
            if (this.teoriaCambio.riesgos) this.teoriaCambio.riesgos = this.teoriaCambio.riesgos.split(';')
            var elementos = []
            elementos.push(axios({
                method: 'put',
                url: '/ficha_instrumentos/api/editar_tdc/' + this.teoriaCambio.id + '/',
                data: this.teoriaCambio
            }))
            this.categorias.forEach(categoria => {
                categoria.elementos.forEach((elemento, index) => {
                    var elementoDict = {}
                    elemento.orden = index + 1
                    elementoDict = {
                        method: 'put',
                        url: '/ficha_instrumentos/api/crear_elemento_tdc/' + elemento.id + '/',
                        data: elemento
                    }
                    elementos.push(axios(elementoDict))
                })
            })
            if (elementos.length > 0) {
                this.show = true
                axios.all(elementos)
                    .then((response) => {
                        this.show = false
                        this.getData()
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            title: 'Teoría de Cambio guardada exitosamente'
                        })
                    })
                    .catch((response) => {
                        this.show = false
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Error al guardar la Teoría de Cambio'
                        })
                    })
            }
        },
        openModal (categoria, elemento, editar) {
            this.elemento = elemento
            this.editar = editar
            this.titulo = categoria.nombre
            this.tipoIndicadoroId = categoria.id
            if (!this.elemento.indicadores) this.elemento.indicadores = []
            Indicador.query({ tipo_indicador: categoria.id, ficha: this.fichaId, instrumento: this.instrumentoId }).then((res) => {
                this.indicadores = res.data
                // console.log(res.data)
            }, function (error) {
                console.log(error)
            })
            this.$root.$emit('bv::show::modal', 'modal-create-elemento')
        },
        closeModal () {
            this.$root.$emit('bv::hide::modal', 'modal-create-elemento')
        },
        onGroupsChange (e) {
            console.log('dragtest')
            this.categorias.forEach(categoria => {
                categoria.elementos.forEach((elemento, index) => {
                    elemento.orden = index + 1
                })
            })
            // this.reDrawAll()
            // this.trythis()
        },
        addLine (elemento) {
            if (!this.posIni) {
                this.posIni = this.$refs['elemento_' + elemento.id][0]
                this.elementoIni = elemento
            } else {
                this.posEnd = this.$refs['elemento_' + elemento.id][0]
                this.elementoEnd = elemento
            }
            if (this.posIni && this.posEnd) {
                if (this.elementoIni.tipo_indicador <= this.elementoEnd.tipo_indicador) {
                    if (this.elementoIni.id !== this.elementoEnd.id) {
                        elemento.elementos_padre.push(this.elementoIni.id)
                        this.updateElemento(elemento)
                    } else {
                        this.posIni = null
                        this.posEnd = null
                        this.elementoIni = null
                        this.elementoEnd = null
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Elemento no se puede conectar a si mismo'
                        })
                    }
                    // LeaderLine.setLine(this.posIni, this.posEnd, { color: '#122999', gradient: true, size: 2, path: 'grid' })
                } else {
                    this.posIni = null
                    this.posEnd = null
                    this.elementoIni = null
                    this.elementoEnd = null
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Conexiones son solo de Izquierda a Derecha'
                    })
                }
            }
        },
        line (elemento) {
            var count = 1
            if (!this.colores[elemento.id]) {
                this.colores[elemento.id] = '#' + Math.floor(Math.random() * 16777215).toString(16)
            }
            // console.log(elemento)
            elemento.elementos_padre.forEach((padre, index) => {
                var ini = document.getElementById('elemento_' + padre)
                var end = document.getElementById('elemento_' + elemento.id)
                var anchorspace = 50
                var tl = elemento.elementos_padre.length
                if (ini && end) {
                    elemento.elementos_padres_data.forEach((pp) => {
                        if (pp.tipo_indicador__abreviatura === elemento.tipo_indicador__abreviatura) {
                            tl = tl - 1
                        }
                    })
                    var catFather = this.getCat(elemento.elementos_padres_data, padre)
                    if (tl > 1) {
                        anchorspace = 100 / tl * count - 50 / tl
                    }
                    var lineId = padre.toString() + elemento.id.toString()
                    // LeaderLine.setLine(ini, end, { color: '#122999', gradient: true, size: 2, path: 'grid' })
                    if (catFather === elemento.tipo_indicador__abreviatura) {
                        // var a = new LeaderLine(ini, end, { color: this.colores[padre], startSocket: 'bottom', endSocket: 'top', gradient: true, size: 3, path: 'straight' }) // eslint-disable-line no-new
                        if (!this.lineas[lineId]) {
                            this.lineas[lineId] = new LeaderLine(ini, end, { color: this.colores[padre], startSocket: 'bottom', endSocket: 'top', gradient: true, size: 3, path: 'straight' }) // eslint-disable-line no-new
                        } else {
                            this.lineas[lineId].setOptions({ start: ini, end: ini, color: this.colores[padre], startSocket: 'bottom', endSocket: 'top', gradient: true, size: 3, path: 'straight' })
                        }
                    } else {
                        count += 1
                        // var b = new LeaderLine(ini, LeaderLine.pointAnchor(end, { x: 0, y: anchorspace.toString(10) + '%' }), { startSocketGravity: 80, endSocketGravity: 50, color: this.colores[padre], startSocket: 'right', endSocket: 'left', gradient: true, size: 3, path: 'fluid' }) // eslint-disable-line no-new
                        if (!this.lineas[lineId]) {
                            this.lineas[lineId] = new LeaderLine(ini, LeaderLine.pointAnchor(end, { x: 0, y: anchorspace.toString(10) + '%' }), { startSocketGravity: 80, endSocketGravity: 50, color: this.colores[padre], startSocket: 'right', endSocket: 'left', gradient: true, size: 3, path: 'fluid' }) // eslint-disable-line no-new
                        } else {
                            this.lineas[lineId].setOptions({ start: ini, end: LeaderLine.pointAnchor(end, { x: 0, y: anchorspace.toString(10) + '%' }), startSocketGravity: 80, endSocketGravity: 50, color: this.colores[padre], startSocket: 'right', endSocket: 'left', gradient: true, size: 3, path: 'fluid' })
                        }
                    }
                }
            })
        },

        getCat (arr, cod) {
            if (arr) {
                return arr.filter(d => d.id === cod)[0].tipo_indicador__abreviatura
            }
        },
        removeLine (padre, elemento) {
            const index = elemento.elementos_padre.indexOf(padre)
            elemento.elementos_padre.splice(index, 1)
            // this.unDrawLine(padre.toString() + elemento.id.toString())
            this.updateElemento(elemento)
        },
        reloadPage () {
            window.location.reload()
        },
        trythis () {
            // console.log(this.lineas)
            // this.lineas[734].remove() // funciona
            // this.lineas[734].color = '#fffff' // no funciona
            if (Object.keys(this.lineas).length > 0) {
                // console.log(this.lineas)
                // console.log('check')
                for (var key in this.lineas) {
                    // console.log(key)
                    this.lineas[key].remove()
                }
            }
            this.lineas = {}
            // console.log(this.lineas)
        },
        unDrawLine (key) {
            this.lineas[key].remove()
        },
        reDrawAll (data) {
            // console.log('debug')
            this.trythis()
            if (data) {
                this.elementostdc = data
            }
            this.elementostdc.map((value, key) => {
                // console.log(value)
                // console.log('test')
                this.line(value)
            })
            this.ready = true
            this.optCount += 1
        },
        updateLinesDrag () {
            for (var key in this.lineas) {
                // console.log(key)
                this.lineas[key].position()
            }
        }
    },
    mounted () {
        // this.lineas = {}
        // this.trythis()
        this.getData()
        UsuarioActual.query({}).then((res) => {
            this.usuario = res.data[0]
            if (this.usuario.tipo_usuario === 'Administrador' || this.usuario.tipo_usuario === 'Revisor Ministerio' || this.usuario.is_superuser) {
                this.isAdmin = true
            }
        })
        // console.log(Object.keys(this.lineas).length)
    },
    beforeDestroy () {
        this.trythis()
        // console.log('Mounted destroyed')
    },
    updated () {
        /* si no se dibujan las lineas, con esto deberia, ni idea de como, solo se me ocurrio esta solucion */
        if (this.ready && this.optCount < 2) {
            // console.log('desync')
            this.reDrawAll()
            this.ready = false
        }
        this.updateLinesDrag() // console.log('hello')
    }
}
</script>
